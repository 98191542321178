import React, { useState } from 'react';
import { Link as RouterLink, useHistory, useLocation, useParams  } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik ,Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Box,  
  Container,  
  Typography,
  makeStyles,
  Link
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Button, Form } from "../../components/Wrappers";
import { useHelperStyles } from '../../styles'
import {useApiHttpState} from '../../context/ApiContext'

//import FacebookIcon from 'src/icons/Facebook';
//import GoogleIcon from '../../icons/Google';
import Page from '../../components/Page';
import Header from '../../components/Header'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ResetPassword = () => { 
    const { token } = useParams();  
    const [resetSuccess, setResetSuccess] = useState(false)  
    const {loading,api} = useApiHttpState()
    const location = useLocation();
    const history = useHistory();
    const helperClasses = useHelperStyles()

  return (
    <Page      
      title="Reset Password"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Header history={history} />  
        <Container maxWidth="sm">
            {!!token && 
                <Formik
                    initialValues={{                    
                        password: '',
                        passwordConfirm: ''
                    }}
                    validationSchema={ Yup.object().shape({                        
                        password: Yup.string().max(255).required('Password is required'),
                        passwordConfirm: Yup.string().max(255).required('Confirm Password is required').oneOf([Yup.ref('password'), null], 'Passwords must match')
                    })}
                    onSubmit={async (values, { setSubmitting, setStatus }) => {
                        setSubmitting(true)
                        setStatus('')
                        const [er,response] = await api.put('/users/resetPasswordWithToken',{token:token,password:values.password})
                        
                        if (!er) {
                            const res = response.data;
                            const { state } = location;
                            if (state && state.from) {
                                history.replace(state.from.pathname);
                            } else {
                                history.replace('/app/dashboard');
                            }                    
                        } else {
                            setStatus(er.response.data.message)
                        }
                        setSubmitting(false);
                    }}
                >
                {({ isSubmitting , status             
                }) => (
                  <Form>
                    <Box mb={3}>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                      >
                        Reset Password
                      </Typography>
                    </Box> 
                    <Field
                        component={TextField}
                        name="password"
                        type="password"
                        label="Password"
                        variant="outlined"
                        margin="normal"                                        
                        fullWidth
                    />                
                    <Field
                        component={TextField}
                        name="passwordConfirm"
                        type="password"
                        label="Confirm Password"
                        variant="outlined"
                        margin="normal"                                        
                        fullWidth
                    />                
                    <Box my={2}>
                        {!!status &&
                            <Alert severity="error" variant="filled" className={helperClasses.marginTopBottom}>{status}</Alert>
                        }  
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Save New Password
                      </Button>
                    </Box>
                  </Form>
                )}
                </Formik>
            }
            {!token && !resetSuccess &&
                <Formik
                    initialValues={{
                    email: ''
                    }}
                    validationSchema={Yup.object().shape({
                    email: Yup.string().email('Must be a valid email').max(255).required('Email is required')              
                    })}
                    onSubmit={async (values, { setSubmitting, setFieldError }) => {
                        setSubmitting(true)
                        const [er,response] = await api.post('/users/resetPassword',{email:values.email})
                        if (!er) {
                            const user = response.data; 
                            setResetSuccess(true)
                        } else {
                            setFieldError("email","Email did not match!")
                        }
                        setSubmitting(false);
                    }}
                >
                {({ isSubmitting }) => (
                <Form>
                    <Box mb={3}>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Reset Password
                    </Typography>
                    </Box> 
                    <Field
                        component={TextField}
                        name="email"
                        type="email"
                        label="Email Address"
                        variant="outlined"
                        margin="normal"                                        
                        fullWidth
                    />              
                    <Box my={2}>
                    <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                        Reset Password
                    </Button>
                    </Box>
                </Form>
                )}
            </Formik>
        }
        {!!resetSuccess &&
            <Box mb={3}>                
                <Alert severity="success" variant="filled" className={helperClasses.marginTopBottom}>
                    <Typography variant="h3" >
                        An email was went with instuctions how to reset you password
                    </Typography>
                </Alert>
            </Box> 
        }
        </Container>
      </Box>
    </Page>
  );
};

export default ResetPassword;