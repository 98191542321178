import React from "react";

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SmsIcon from '@material-ui/icons/Sms';

import UpsertForm from './UpsertForm'
import { Typography } from "../../components/Wrappers";
import { useHelperStyles } from '../../styles'
import { useOpenDialog, useOpenSmsDialog } from "../../context/DialogContext";
import {formatPhoneNumber,getFullName} from '../../utils'

const HouseholdHeader = ({household,mutate}) => {

    const openDialog = useOpenDialog()
    const openEdit = () => {  
        const Content = <div><UpsertForm household={household} mutate={mutate} /></div>
        openDialog("Update Household",Content,(close) => close())
      }
    const openSmsDialog = useOpenSmsDialog()    
    const openSendSms = (phone) => {
      openSmsDialog(phone.phoneNumber,``)
    }
    const helperClasses = useHelperStyles()  

    return (
        <Paper className={helperClasses.paperPadding}>
            <div>
                <IconButton aria-label="edit"  style={{float:"right"}} size="small" onClick={openEdit} >
                    <EditIcon fontSize="inherit" />
                </IconButton>                
                <Typography align="center" component="div">
                    <Typography variant="h3">
                        {getFullName(household)}                        
                    </Typography>                    
                    <Typography variant="h6">
                        {household.address}  {household.city}{household.city && household.state ? ',' : ''}{household.state} {household.postalCode}
                    </Typography>
                    <Typography variant="body1">
                        {household.phoneNumbers && household.phoneNumbers.map((phone,i) => {
                            const last = household.phoneNumbers.length - 1 == i;
                            return <>
                                <IconButton aria-label="edit" size="small" onClick={() => openSendSms(phone)} >
                                    <SmsIcon fontSize="inherit" />
                                </IconButton> {`${formatPhoneNumber(phone.phoneNumber)} - ${phone.phoneType}${!last ? ", " : ""}`}
                            </>
                        })}
                    </Typography>
                    <Typography variant="body1">
                        {household.emails && household.emails.map(email => {
                            return <a key={email.id} href={`mailto:${email.email}`}>{email.email}</a>
                        })}
                    </Typography>
                </Typography>
            </div>
        </Paper>
    )
}

export default HouseholdHeader