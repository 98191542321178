import React from "react";

import { useOpenDialog  } from "../../context/DialogContext";

import {formatPhoneNumber} from '../../utils'
import {useHouseholds} from '../../apis'

import {
    Grid
} from "@material-ui/core";
import MUIDataTable from "../../components/DataTable"
import AddToolButton from "../../components/DataTable/AddToolButton"
import { Button } from "../../components/Wrappers";
import UpsertForm from "./UpsertForm";
import {useHelperStyles} from '../../styles'


const Households = ({history}) => {       
    const openDialog = useOpenDialog()
    const helperClasses = useHelperStyles()
    
    const openHousehold = (household) => {
        history.push('/app/household/'+household.id)
    }
    const {households,isLoading,isError} = useHouseholds()
    
    const columns = [
        {name : "familyName", label: "Family Name", options: { filter: false }},
        {name : "husbandName", label: "Husband's Name", options: { filter: false }},
        {name : "wifeName", label: "Wife's Name", options: { filter: false }},
        {name : "address", label: "Address" , options: { filter: false }},
        {name : "city", label: "City"},
        {name : "state", label: "State"},
        {name : "postalCode", label: "Zip"},
        {name : "phoneNumbers", label: "Phone" , options: { 
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = households[dataIndex]; 
            return row.phoneNumbers.map(phone => formatPhoneNumber(phone.phoneNumber)).join(", ")
          }
        }},
        {name : "emails", label: "Email" , options: {           
          customBodyRenderLite: (dataIndex) => {
            const row = households[dataIndex]; 
            return row.emails ? row.emails.map(email => email.email).join(", ") : ""
          }
        }},
        {name: " ", options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              const row = households[dataIndex]; 
              return (
                <div className={helperClasses.floatRight}>
                  <Button variant="contained" size="small" color="success" className={helperClasses.marginLeft} onClick={() => openHousehold(row)}>View</Button>
                </div>
              );
            }
          }
        },
    ]
    const options = {
        customToolbar: () => {
            return (
                <AddToolButton handleClick={() => openDialog("Create New Household" , <UpsertForm /> )}  />
            );
        },
        customSearch: (searchQuery, currentRow, columns) => {
          const searchText = searchQuery.toLowerCase()          
          const data = currentRow.reduce((acc,val,idx) => {            
            acc[columns[idx].name] = typeof val == "string" ? val.toLowerCase() : val;
            return acc;
          },{})          
          if(data.familyName.indexOf(searchText) == 0) return true
          if(data.husbandName.indexOf(searchText) == 0) return true
          if(data.wifeName.indexOf(searchText) == 0) return true
          if(data.phoneNumbers) {
            let matches = data.phoneNumbers.filter(phone => phone.phoneNumber && phone.phoneNumber.indexOf(searchText) > -1)
            if(matches.length) return true
          }
          if(data.emails) {
            let matches = data.emails.filter(email => email.email.indexOf(searchText) > -1)
            if(matches.length) return true
          }
          if(data.address.indexOf(searchText) == 0) return true
          //if(data.city.indexOf(searchText) == 0) return true
          //if(data.state.indexOf(searchText) == 0) return true
          //if(data.postalCode.indexOf(searchText) == 0) return true
          
          return false
        },
        sortOrder:{name: 'familyName', direction: 'asc'}
    }
    return (
        <>         
          <Grid container spacing={4}>  
            <Grid item xs={12}>
                <MUIDataTable title={"Households Information"} data={households? households : []} columns={columns} options={options} />
            </Grid>
          </Grid>
        </>
      );
}

export default Households