import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { DialogProvider } from "./context/DialogContext";
import { SnackbarProvider } from "./context/SnackbarContext";
import { ApiHttpProvider } from "./context/ApiContext";
import { SocketProvider } from "./context/SocketContext";
import { CacheProvider } from "./context/CacheContext";



import {StoreProvider} from './store'

const options = {}

ReactDOM.render(
  <StoreProvider>
    <LayoutProvider>
        <CacheProvider>
          <ApiHttpProvider>
            <UserProvider>
              <SocketProvider>
                <ThemeProvider theme={Themes.default}>
                  <CssBaseline />
                  <SnackbarProvider>
                    <DialogProvider>
                      <App />
                    </DialogProvider>
                  </SnackbarProvider>
                </ThemeProvider>
              </SocketProvider>
            </UserProvider>
          </ApiHttpProvider>
        </CacheProvider>      
    </LayoutProvider>
  </StoreProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
