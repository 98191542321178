export const formatPhoneNumber = (phoneNumber) => {
	let phoneNumber2 = (""+phoneNumber).replace(/\D/g, '');
	phoneNumber2 = phoneNumber2.replace(/^1/g,"")
    let m = phoneNumber2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return (!m) ? phoneNumber : "(" + m[1] + ") " + m[2] + "-" + m[3];
}

export const getFullName = (household) => {
    const familyName = household ? household.familyName : ""
    const husbandName = household ? household.husbandName : ""
    const wifeName = household ? household.wifeName : ""
    return `${familyName}${!!familyName && (!!husbandName || !!wifeName) ? "," : ""} ${husbandName} ${(!!husbandName && !!wifeName) ? '&' : ''} ${wifeName}`
}

export const camelCaseToWords = (text) => {    
    const result = text.replace( /([A-Z])/g, " $1" );
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}