import React, { useEffect } from "react";
import dateFormat from 'date-fns/format'

import { Button } from "../../components/Wrappers";
import MUIDataTable from "../../components/DataTable"
import AddToolButton from "../../components/DataTable/AddToolButton"
import HouseholdHeader from './HouseholdHeader'
import UpsertForm from '../householdCases/UpsertForm'
import HouseholdCallLogs from './CallLogs'

import { useParams } from "react-router-dom";
import { useOpenDialog , useCloseDialog } from "../../context/DialogContext";
import {useHouseholdHouseholdCases,useHousehold} from "../../apis"

const Household = ({history}) => {
    const {householdId} = useParams()    
    const openDialog = useOpenDialog()
    
    
    const openCase = (householdCase) => {
        history.push(`/app/household/${householdId}/householdCase/${householdCase.id}`)
    }
         
    const {household,isLoading,error : householdError,mutate} = useHousehold(householdId)

    const {householdCases,isLoading:isLoadingCases,error: casesError} = useHouseholdHouseholdCases(householdId)
     
    const escalationMap = {1:"Regular",2:"Urgent"}    
    const options = {
        selectableRows:"none",
        customToolbar: () => {
            return (
                <AddToolButton handleClick={() => openDialog("Open New Case" , <UpsertForm household={household} /> )}  />
            );
        }
    }
    const columns = [
        
        {name:"caseTypeName" , label:"Case Type"},
        {name:"createdAt", label: "Date" , options : {customBodyRender: value => dateFormat(new Date(value),"MMM do, yyyy")}},
        {name:"statusName" , label:"Status"},
        {name:"assignedToEmail" , label:"Assigned To"},
        {name:"volunteers" , label:"Volunteer", options : {customBodyRender: (values) => values ? values.map(volunteer => volunteer.firstName + " " + volunteer.lastName).join(",")  : ""}},
        {name:"escalationLevel" , label:"Escalation", options : {customBodyRender: value => escalationMap[value]}},
        {name:"" , options : {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
                const row = householdCases[dataIndex]; 
                return (
                    <Button variant="contained" size="small" color="success" onClick={() => openCase(row)}>View</Button>
                )
            } 
        } }
    ];

    return (
        <div>
            {!!household && 
                <>
                    <HouseholdHeader household={household} mutate={mutate} />                
                    <br />
                    <MUIDataTable title={"Household Cases"} data={householdCases} columns={columns} options={options} />
                    <br />
                    {!!household && <HouseholdCallLogs household={household} />}
                </>
            }
        </div>
    )
}

export default Household