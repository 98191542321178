import React, {useEffect, createContext} from "react";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

//import api from '../utils/api'
import {useApiHttpState} from './ApiContext'

const UserStateContext = createContext();
const UserDispatchContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true , user: action.payload };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false , user: null};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


function UserProvider({ children }) {
  const classes = useStyles();
  //const [loading,setLoading] = React.useState(false)
  const {loading,api} = useApiHttpState()
  
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: false, user : null
  });
   
  const loadUser = async  (userState = state, showLoader) => {
    //if(showLoader) setLoading(true)
    const [er,response] = await api.get('/users/current',{useLoader:showLoader})
    //if(showLoader) setLoading(false)
    if (!er) {
      const user = response.data
      if(!userState.isAuthenticated) {
        dispatch({type:"LOGIN_SUCCESS",payload:user})
      }
    } else {
      if(!!userState.isAuthenticated)dispatch({type:"SIGN_OUT_SUCCESS"})
    }       
  }

  useEffect(() => {
    loadUser(state,true)    
    const interval = setInterval(() => {      
      if(state.isAuthenticated) loadUser(state)
    }, 60000);
    return () => clearInterval(interval);
  },[state])  

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {!!loading &&
          <Backdrop className={classes.backdrop} open >
            <CircularProgress color="inherit" />
          </Backdrop>
        }
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useLoginUser, useSignOutUser, useUserDispatch, loginUser, signOut };

// ###########################################################

const useLoginUser = () => {
  const dispatch = useUserDispatch()
  dispatch({ type: 'LOGIN_SUCCESS' })  
}
const useSignOutUser = () => {
  const dispatch = useUserDispatch()  
  dispatch({ type: "SIGN_OUT_SUCCESS" });  
}

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    setTimeout(() => {
      localStorage.setItem('id_token', 1)
      setError(null)
      setIsLoading(false)
      dispatch({ type: 'LOGIN_SUCCESS' })

      history.push('/app/dashboard')
    }, 2000);
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}
