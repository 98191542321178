import React from 'react'
import { Field  } from 'formik';
import { TextField } from 'formik-material-ui';
import { TimePicker, DatePicker, KeyboardDatePicker, DateTimePicker } from 'formik-material-ui-pickers';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem'
import { useHelperStyles } from '../../styles'
import { camelCaseToWords } from '../../utils'

const CaseInfoField = ({name,label,width,fullWidth=true,componentType="TextField",select=false,options=null,...props}) => {
    const componentsMap = { TextField : TextField, TimePicker : TimePicker, DatePicker: DatePicker, KeyboardDatePicker : KeyboardDatePicker, DateTimePicker : DateTimePicker };
    const Component = componentsMap[componentType] 
    const helperClasses = useHelperStyles()     
    return (
        <Grid item xs={width} className={helperClasses.itemPadding}>
            {!select && 
                <Field
                    component={Component}
                    name={`caseInfo.${name}`}
                    label={label ? label : camelCaseToWords(name)}
                    fullWidth={fullWidth}
                    type="text"
                    variant="outlined"
                    inputVariant="outlined"
                    clearable
                    margin="normal"
                    size="small"   
                    {...props}         
                />
            }
            {!!select && <Field
                component={TextField}
                name={`caseInfo.${name}`}
                label={label ? label : camelCaseToWords(name)}
                fullWidth={fullWidth}
                type="text"
                variant="outlined"
                margin="normal"
                size="small"
                select
                {...props}
            >
                {!!options.length && options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label ? option.label : option.value}
                    </MenuItem>
                ))}
            </Field>}
        </Grid>
    )
}

export default CaseInfoField