//import { yesNoOptions } from './caseInfoFieldsMetaData'

const maritalOptions = [
    {value:"",label:"Choose Option"},
    {value:"Married"},
    {value:"Single"},
    {value:"Divorced"},
]
const yesNoOptions = [
    {value:"",label:"Choose Option"},
    {value:"Yes"},
    {value:"No"},
]

const FoodVoucherFields = {
    name:{default:"",label:"Name",width:4},
    homePhone:{default:"",label:"Home Phone",width:4},
    cellPhone:{default:"",label:"Cell Phone",width:4},
    
    homeAddress:{default:"",label:"Home Address",width:12},

    maritalStatus:{default:"",label:"Marital Status",width:4,select:true,options:maritalOptions},
    employmentOfCaller:{default:"",label:"Employment Of Caller",width:4},
    employmentOfSpouse:{default:"",label:"Employment Of Spouse",width:4},

    numberOfChildren:{default:"",label:"Number Of Children",width:3},
    occupantsInHousehold:{default:"",label:"Occupants In Household",width:3},
    
    shulAffiliation:{default:"",label:"Shul Affiliation",width:3},
    shulRabbi:{default:"",label:"Shul Rabbi",width:3},

    eligibilityForPrograms:{default:"",label:"",width:4,helperText:"(i.e. Food Stamps, WIC, Medicaid)"},
    receivedPastAchiezerAid:{default:"",label:"Received Past Achiezer Aid",width:4,select:true,options:yesNoOptions},
    receivingOtherAssistance:{default:"",label:"",width:4,helperText:"(i.e. other organizations or indivduals)"},

     
}
/*
const FoodVoucherFields = () => {
    const helperClasses = useHelperStyles()  
    
    return (
        <>            
            <Grid item xs={4}>
                <Field
                    component={TextField}
                    name="caseInfo.name"
                    type="text"
                    label="Name"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={8}>
                <Field
                    component={TextField}
                    name="caseInfo.homeAddress"
                    type="text"
                    label="Home Address"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                />                
            </Grid>
            <Grid item xs={4}>
                <Field
                    component={TextField}
                    name="caseInfo.homePhone"
                    type="text"
                    label="Home Phone"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <Field
                    component={TextField}
                    name="caseInfo.cellPhone"
                    type="text"
                    label="Cell Phone"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid>            
            <Grid item xs={4}>
                <Field
                    component={TextField}
                    name="caseInfo.maritalStatus"
                    type="text"
                    label="Marital Status"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                
            </Grid>
            <Grid item xs={4}>
                
            </Grid>    
            <Grid item xs={4}>
                
            </Grid> 
            <Grid item xs={12}>
                
            </Grid>
        </>
    )
}*/

export default FoodVoucherFields

