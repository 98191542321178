const maritalOptions = [
    {value:"",label:"Choose Option"},
    {value:"Married"},
    {value:"Single"},
    {value:"Divorced"},
]
const yesNoOptions = [
    {value:"",label:"Choose Option"},
    {value:"Yes"},
    {value:"No"},
]

const isHidden = (name,values) => true

const familyInCrisisFields = {
    name:{default:"",label:"",width:4,isHidden},
    neighborhood:{default:"",label:"",width:4,isHidden},   
    
    homeAddress:{default:"",label:"",width:12,isHidden},

    maritalStatus:{default:"",label:"Marital Status",width:4,select:true,options:maritalOptions,isHidden},
    shulAffiliation:{default:"",label:"Shul Affiliation",width:4,isHidden},
    shulRabbi:{default:"",label:"Shul Rabbi",width:4,isHidden},

    previousContactWithAchiezer:{default:"",label:"",width:6,helperText:"Have you contacted someone at Achiezer before? Who?",isHidden},
    childrenSchools:{default:"",label:"",width:6,helperText:"Which schools do children attend",isHidden},

    otherOrganizations:{default:"",label:"",width:12,helperText:"Are other organizations actively involved and helping now",isHidden},
    notes:{default:"",label:"",width:12},
}

export default familyInCrisisFields

