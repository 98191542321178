import React from 'react'
import { Field  } from 'formik';
import { TextField } from 'formik-material-ui';
import { TimePicker, DatePicker } from 'formik-material-ui-pickers';
import Grid from '@material-ui/core/Grid';

const RideFields = {
    name:{default:"",label:"Name",width:4},
    phoneNumber:{default:"",label:"Phone Number",width:4},
    date:{default:"",label:"Date",componentType:"DatePicker",width:4,default:null},
    pickupAddress:{default:"",label:"Pickup Address",width:6},
    destinationAddress:{default:"",label:"Destination Address",width:6},
    totalPeople:{default:"",label:"Total People",width:4},
    originationPickupTime:{default:null,label:"Orgination Pickup Time",width:4,componentType:"TimePicker"}, 
    destinationPickupTime:{default:null,label:"Destination Pickup Time",width:4,componentType:"TimePicker"},
    notes:{default:"",label:"Notes",width:12,multiline:true,rows:4},
}

/*const RideFields = () => {
    
    return (
        <>
            <Grid item xs={4}>
                <Field
                    component={TextField}
                    name="caseInfo.name"
                    type="text"
                    label="Name"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <Field
                    component={TextField}
                    name="caseInfo.phoneNumber"
                    type="text"
                    label="Phone Number"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                />                
            </Grid>
            <Grid item xs={4}>
                <Field
                    component={DatePicker}
                    name="caseInfo.date"
                    type="text"
                    label="Date"
                    inputVariant="outlined"
                    clearable
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={TextField}
                    name="caseInfo.pickupAddress"
                    type="text"
                    label="Pickup Address"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={TextField}
                    name="caseInfo.destinationAddress"
                    type="text"
                    label="Destination Address"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid>
            <Grid item xs={4}>
                <Field
                    component={TextField}
                    name="caseInfo.totalPeople"
                    type="text"
                    label="Amount of People"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                /> 
            </Grid>
            <Grid item xs={4}>
                <Field
                    component={TimePicker}
                    name="caseInfo.originationPickupTime"
                    type="text"
                    label="Orgination Pickup Time"
                    inputVariant="outlined"
                    clearable
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid>    
            <Grid item xs={4}>
                <Field
                    component={TimePicker}
                    name="caseInfo.destinationPickupTime"
                    type="text"
                    label="Destination Pickup Time"
                    inputVariant="outlined"
                    clearable
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid> 
            <Grid item xs={12}>
                <Field
                    component={TextField}
                    name="caseInfo.notes"
                    type="text"
                    label="Notes"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                />
            </Grid>   
        </>
    )
}

*/



export default RideFields
//export {rideValues}



