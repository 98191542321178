import React, { useEffect }  from 'react';
import { Link as RouterLink, useHistory, useLocation  } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik ,Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Box,  
  Container,  
  Link,  
  Typography,
  makeStyles
} from '@material-ui/core';
import { Button, Form } from "../../components/Wrappers";

import {useApiHttpState} from '../../context/ApiContext'

//import FacebookIcon from 'src/icons/Facebook';
//import GoogleIcon from '../../icons/Google';
import Page from '../../components/Page';
import Header from '../../components/Header'
import { useUserDispatch,  useUserState } from "../../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Login = () => {  
  const {loading,api} = useApiHttpState()
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const userDispatch = useUserDispatch(); 
  var { isAuthenticated } = useUserState(); 
  
  useEffect(() => {
    if(isAuthenticated) {      
      if (state && state.from) {
        history.replace(state.from.pathname);
      } else {
        history.replace('/app/dashboard');
      }       
    }
  },[]) 
  return (
    <Page      
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Header history={history} loginHeader={true} />  
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
                setSubmitting(true)
                const [er,response] = await api.post('/users/login',{userName:values.email,password:values.password})
                if (!er) {
                    const user = response.data;                    
                    userDispatch({type:"LOGIN_SUCCESS",payload:user}) 
                    if (state && state.from) {
                      history.replace(state.from.pathname);
                    } else {
                      history.replace('/app/dashboard');
                    }                    
                } else {
                  if(er.response.data.message == "Invalid CSRF token") {
                    setFieldError("password","Token Expired!")
                    window.location.reload()
                  } else {
                    setFieldError("password","Email or password do not match!")
                  }
                }
                setSubmitting(false);
            }}
          >
            {({                          
              isSubmitting              
            }) => (
              <Form>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Sign in
                  </Typography>
                </Box> 
                <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email Address"
                    variant="outlined"
                    margin="normal"                                        
                    fullWidth
                />               
                <Field
                    component={TextField}
                    name="password"
                    type="password"
                    label="Password"
                    variant="outlined"
                    margin="normal"                                        
                    fullWidth
                />                
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Forgot your password?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/resetPassword"
                    variant="h6"
                  >
                    Reset password
                  </Link>
                </Typography>
              </Form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default Login;