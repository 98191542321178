import React from "react";
//import moment from 'moment-timezone'
import dateFormat from 'date-fns/format'
import MUIDataTable from 'mui-datatables'

// styles
import useStyles from "./styles";

import { Typography } from "../../components/Wrappers";
import { Button } from "../../components/Wrappers";
import {formatPhoneNumber} from '../../utils'

import { useOpenDialog ,  useOpenSmsDialog} from "../../context/DialogContext";

import UpsertForm from '../household/UpsertForm'

const Table = ({callLogs,title = "Call Logs",history, loadCallerCallLogs,phoneNumberTypes,hideActions}) => {
    const classes = useStyles();
    const openDialog = useOpenDialog()
    const openSmsDialog = useOpenSmsDialog()
    const openEdit = (callLog) => {   
      if(callLog.householdId) return  history.push('/app/household/'+ callLog.householdId)  
      const Content = <div><UpsertForm phoneNumber={callLog.outgoing ? callLog.receiverId : callLog.callerId} history={history} /></div>
      openDialog("Create Household",Content,(close) => close())
    }
    const openSendSms = (callLog) => {
      openSmsDialog(`${callLog.outgoing ? callLog.receiverId : callLog.callerId}`,``)
    }
    const openKnowledgeBase = (callLog) => {
      history.push('/app/knowledgeBase?fromNumber='+encodeURIComponent(callLog.outgoing ? callLog.receiverId : callLog.callerId))
    }
    const options = {
        download:false,
        print:false,
        viewColumns:false,
        selectableRows:"none",
        sortOrder: {
            name: 'id',
            direction: 'desc'
        },
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,50,100]
    };
    
    const escalationLevelMap = {
        "2" : "Urgent",
        "3" : "Emergency"
    }

    const columns = [
        {name:"id" , options : {display: "excluded"}},
        {name : "callerName", label: "Caller Info", options: { customBodyRenderLite : (dataIndex) => {
          const row = callLogs[dataIndex];
          const outgoing = row.outgoing
          return (
            <div>
              <Typography color="text" colorBrightness="secondary">{!outgoing && row.callerName }</Typography>
              <Typography color="text" size="md">{formatPhoneNumber(!outgoing ? row.callerId : row.receiverId )}</Typography>              
              <Typography color="text" size="sm" colorBrightness="secondary">{/*moment.tz(row.startedAt,'America/New_York').format("MMM Do, h:mm:ssa")*/dateFormat(new Date(row.startedAt),"MMM do, h:mm:ssa")}{row.finishedAt ? " - " + /*moment.tz(row.finishedAt,'America/New_York').format("h:mm:ssa")*/dateFormat(new Date(row.finishedAt),"MMM do, h:mm:ssa"):""}</Typography>
              
            </div>
          )
        }
        }},
        {name : "receiverId", label: "Dispatcher Info", options: { 
          customBodyRenderLite : (dataIndex) => {
            const row = callLogs[dataIndex];  
            const outgoing = row.outgoing;            
            return (
              <div>             
                <Typography color="text">{outgoing ? "From" : "Dialed"} Number: {formatPhoneNumber(outgoing ? row.callerId : row.initialRecieverId)}</Typography>   
                <Typography color="text">{outgoing ? "Called" : "Answered"} By: {formatPhoneNumber(outgoing ? row.callerId : row.receiverId)} {row.extension < 1000 ? "ext. " + row.extension : ""}</Typography> 
                <Typography color="text">Direction: {row.outgoing ? "Outgoing":"Incoming"} {!!row.wentToVoicemail && <em>Left voicemail </em>}</Typography>
              </div>
            )
        }
        }},
        {name : "household" , label: "Household Info", options: {
          //customBodyRender : (value) => value ? escalationLevelMap[value] : "Normal" }
          customBodyRenderLite : (dataIndex) => {
            const row = callLogs[dataIndex]; 
            
            return (
              !!row.householdId ?
              <div>             
                <Typography color="text">Name: {`${row.familyName}, ${row.husbandName} & ${row.wifeName}` }</Typography>   
                <Typography color="text">Phone: {row.phoneType}</Typography>  
                <Typography color="text">Open Cases: {row.openCases}</Typography>                
              </div> : <div></div>
            )
          }
        }},
        {name: " ", options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              const row = callLogs[dataIndex]; 
              return (
                <div className={classes.floatRight}>
                  <Button variant="contained" size="small" color="success" className={classes.margin} onClick={() => openEdit(row)}>Household</Button>
                  <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={() => openKnowledgeBase(row)}>K.B.</Button>
                  <Button variant="contained" size="small" color="secondary" className={classes.margin} onClick={() => openSendSms(row)} >Send Sms</Button>                  
                  {!!loadCallerCallLogs && <Button variant="contained" size="small" color="error" className={classes.margin} onClick={() => loadCallerCallLogs(!row.outgoing ? row.callerId : row.receiverId )}>Previous Calls</Button>}
                </div>
              );
            }
          }
        },
        {name : "callerName", label: "Caller Name", options : {display: "excluded"}},
        {name : "callerId", label: "Caller ID", options : {display: "excluded"}},
        {name : "date", label: "Date", options : {display: "excluded"}}, 
    ].filter(col => !hideActions || col.name != ' ');

    return (
        <>
            {!!callLogs &&                    
                <MUIDataTable title={title} data={callLogs} columns={columns} options={options} />                    
            }
        </>
    )

}


export default Table