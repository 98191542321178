import React from "react";
import { get, set, update } from 'idb-keyval';


const CacheContext = React.createContext();

const CacheProvider = ({ children }) => {

    return (
        <CacheContext.Provider value={{get, set, update}}>
            { children}
        </CacheContext.Provider>
    );
}

const useCacheState = () => {
    var context = React.useContext(CacheContext);
    if (context === undefined) {
        throw new Error("useCacheState must be used within a CacheProvider");
    }
    return context;
}

export {CacheProvider,useCacheState}