import React, { useEffect, useState } from 'react';

import { Grid, Box } from "@material-ui/core";
import { Button } from "../../../components/Wrappers";
import MUIDataTable from "../../../components/DataTable"
import AddToolButton from "../../../components/DataTable/AddToolButton"

//import api from '../../../utils/api'
import {useApiHttpState} from '../../../context/ApiContext'

import PageTitle from "../../../components/PageTitle";

import UpsertForm from './UpsertForm'
import { useAddSnackbarQueue} from "../../../context/SnackbarContext";
import { useOpenDialog , useOpenConfirmationDialog, useCloseDialog } from "../../../context/DialogContext";
import { useStoreHelpers } from '../../../store'


  export default function Roles() { 
    const  {getItemsArray : getItems , addItems,  removeItem } = useStoreHelpers("roles")     
    //const [loading,setLoading] = useState(false)
    const {loading,api} = useApiHttpState()
    const roles = getItems() 
     
    const openDialog = useOpenDialog()
    const openConfirmationDialog = useOpenConfirmationDialog()
    const close = useCloseDialog()
    const addSnackbarQueue = useAddSnackbarQueue() 
    
    const loadData = async () => {     
      const [er,response] = await api.get('/roles/',{useLoader:true})      
      if(!er) {
        addItems(response.data)
      }
    }
    
    const deleteRole = async (role) => {
        const [er] = await api.delete(`/roles/${role.id}`)        
        if(!er) {
            removeItem(role.id)
            close()
            addSnackbarQueue(`The ${role.name} role was deleted`, "success")
        } else {
            addSnackbarQueue("An error occurred when deleting the item", "error")
        }

    }

    useEffect(() => {
        loadData()
    },[])


    const options = {
        customToolbar: () => {
            return (
                <AddToolButton handleClick={() => openDialog("Create New Role" , <UpsertForm /> )}  />
            );
        }
    }
    
    const columns = ["id", "name" ,
    {name: "", options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          const row = roles[dataIndex]; 
          return (
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" size="small" color="warning" margin onClick={() => openDialog("Edit Role" , <UpsertForm role={row} /> )} >Edit</Button>
              <Button variant="contained" size="small" color="error" margin onClick={() => openConfirmationDialog("Are you sure you want to delete the " + row.name + " role?" , () => deleteRole(row)  ,"Confirm Delete" )} >Delete</Button>
            </Box>
          );
        }
      }
    }
    ];
    return (
      <>
        <PageTitle title="Roles" />
        <Grid container spacing={4}>
          <Grid item xs={12}>            
              {!loading && <MUIDataTable data={roles ? roles : []} columns={columns} options={options} />}      
          </Grid>
        </Grid>
      </>
    );
  }