
const mealsNMoreFields = {
    patientName:{default:"",label:"",width:12},
    
    callerName:{default:"",label:"",width:4}, 
    callerNumber:{default:"",label:"",width:4},
    callerRelation:{default:"",label:"",width:4},   
    
    peopleNeeded:{default:"",label:"# of People Food is Needed For",width:4},
    hospital:{default:"",label:"Hospital Name (be specific)",width:4},
    hospitalUnit:{default:"",label:"Hospital Unit and Room Number",width:4},

    contactName:{default:"",label:"",width:4}, 
    contactNumber:{default:"",label:"",width:4}, 
    allergies:{default:"",label:"",width:4}, 

    specialDietaryNeeds:{default:"",label:"",width:12}, 
}

export default mealsNMoreFields

