import React, { useMemo } from "react";
import dateFormat from 'date-fns/format'
import EmailIcon from '@material-ui/icons/Email';
import { Button } from "../../components/Wrappers";
import MUIDataTable from "../../components/DataTable"
import CustomToolButton from '../../components/DataTable/CustomToolButton'
import {useApiHttpState} from '../../context/ApiContext'
import { useUserState } from "../../context/UserContext";
import { useAddSnackbarQueue } from "../../context/SnackbarContext";
import {getFullName} from '../../utils'
import {getHouseholdCaseLabelAndValuesForEmail} from './caseInfoFieldsMetaData'
import { useHouseholdCases } from "../../apis";

const Household = ({history,userOpenCasesView}) => {    
    const { api } = useApiHttpState() 
    const addSnackbarQueue = useAddSnackbarQueue()
      
    const openCase = (householdCase) => {
        history.push(`/app/household/${householdCase.householdId}/householdCase/${householdCase.id}`)
    }
    
    const { user } = useUserState()
    
    const {householdCases,isLoading,isError} = useHouseholdCases(userOpenCasesView)
    const householdCasesMap = useMemo(()=> {
        return householdCases.reduce( (acc,hc) => {acc[hc.id] = hc;return acc},{} )
    },[householdCases])
    const escalationMap = {1:"Regular",2:"Urgent"}  
    
    const emailUserCases = async (cases) => {
        const email = user.email
        let emailHtml = ``
        cases.forEach(householdCase => {
            const household = householdCase.household
            emailHtml += getHouseholdCaseLabelAndValuesForEmail(household,householdCase,[],[],[],[],[])        
            emailHtml += `<br /><br />`
        })
        const [er,response] = await api.post('/email/sendCase',{body:{to:email,subject:"Open Cases",body:emailHtml},useLoader:true})  
        if(!er) {
            addSnackbarQueue(`${cases.length} case${!!cases.length > 1 ? "s" : ""} emailed to ${email}`)
        } else {
            addSnackbarQueue(`An error occurred`,"warning")
        }        
    }
    
    const options = {
        selectableRows:"none",
        customToolbar: () => {
            return userOpenCasesView && householdCases && householdCases.length  ? (
                <CustomToolButton toolTip={"Email yourself open cases"} IconComp={EmailIcon} handleClick={() => emailUserCases(householdCases) }  />
            ) : null ;
        },
        customSearch: (searchQuery, currentRow, columns) => {
            const searchText = searchQuery.toLowerCase()
            const caseId = currentRow[0]
            const householdCase = householdCasesMap[caseId]                    
            const caseInfo = householdCase.caseInfo ? JSON.parse(householdCase.caseInfo) : {};
            return Object.keys(caseInfo).some(key => {
                let value = caseInfo[key];
                value = typeof value == "string" ? value.toLowerCase() : value;                
                if(typeof value == "string" ) {
                    return value.indexOf(searchText) > -1
                }
                return false
            })
            //if(data.city.indexOf(searchText) == 0) return true
            //if(data.state.indexOf(searchText) == 0) return true
            //if(data.postalCode.indexOf(searchText) == 0) return true
            
            return false
          },
          sortOrder:{name: 'createdAt', direction: 'desc'}

    }
    const columns = [
        {name:"id",options:{display:"excluded"}},
        {name:"household",label:"Name", options:{customBodyRender: (value) =>  getFullName(value)}},
        {name:"createdAt", label: "Date" , options : {customBodyRender: value => dateFormat(new Date(value),"MMM do, yyyy")}},
        {name:"caseTypeName" , label:"Case Type"},
        {name:"statusName" , label:"Status"},
        {name:"assignedToEmail" , label:"Assigned To"},
        //{name:"volunteerId" , label:"Volunteer", options : {customBodyRender: (value) => volunteers && value ? volunteers[value].firstName + " " + volunteers[value].lastName : value}},
        {name:"volunteers" , label:"Volunteer", options : {customBodyRender: (values) => values ? values.map(volunteer => volunteer.firstName + " " + volunteer.lastName).join(",")  : ""}},
        {name:"escalationLevel" , label:"Escalation", options : {customBodyRender: value => escalationMap[value]}},
        {name:"createdByEmail" , label:"Created By"},
        {name:"" , options : {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
                const row = householdCases[dataIndex]; 
                const confidential = row.confidential
                const assignedTo = row.assignedTo
                const createdBy = row.createdBy
                const rolesAllowedConfidential = [1,2,5]
                const isViewable = !confidential || user.id == assignedTo || user.id == createdBy || (user.roles.some(role => rolesAllowedConfidential.indexOf(role.id) ))
                return (
                    <Button variant="contained" size="small" color="success" disabled={!isViewable} onClick={() => openCase(row)}>View</Button>
                )
            } 
        } }
    ];
    return (
        <div>
            <> 
                <MUIDataTable title={userOpenCasesView ? "My Open Cases" : "Household Cases"} data={householdCases} columns={columns} options={options} />
            </>            
        </div>
    )
}

export default Household