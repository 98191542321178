import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";
import PublicLayout from "./Layout/PublicLayout";

// pages
import Error from "../pages/error";
import Register from "../pages/login/Register.js";
import Login from "../pages/login";

// context
import { useUserState } from "../context/UserContext";
import { SocketProvider } from "../context/SocketContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        
        <PrivateRoute path="/app" component={Layout} />

        
        <PublicRoute path="/" component={PublicLayout} />
        
        
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>  React.createElement(component, props)   }
        /*render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: props.location.state && props.location.state.from ? props.location.state.from.pathname  : "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }*/
      />
    );
  }
}
