
const meisMitzvahFields = {
    nameOfNiftar:{default:"",label:"",width:4},
    contactName:{default:"",label:"",width:4}, 
    contactNumber:{default:"",label:"",width:4},

    cemetery:{default:"",label:"",width:4},
    dateAndTime:{default:null,label:"",width:4,componentType:"DateTimePicker"},
    peopleNeeded:{default:"",label:"Amount of People needed",width:4},
    
}

export default meisMitzvahFields

