import React from 'react'
import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip';
import { makeStyles } from "@material-ui/core/styles";

import LinearProgress from '@material-ui/core/LinearProgress';
import { Button, Form } from "../../../components/Wrappers";
import { useAddSnackbarQueue} from "../../../context/SnackbarContext";
import { useCloseDialog } from "../../../context/DialogContext";
import { useStoreHelpers } from '../../../store'
import {useApiHttpState} from '../../../context/ApiContext'
import { useHelperStyles } from '../../../styles'
import { useTheme } from '@material-ui/core';
import {transformNumberTo10DigitFormat} from '../../../utils/common'

const useStyles = makeStyles((theme) => ({    
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    }    
  }));



const UpsertForm = ({user,roles}) => {    
    const close = useCloseDialog()    
    const {api} = useApiHttpState()
    const helperClasses = useHelperStyles()
    const chipClasses = useStyles()
    const theme = useTheme()
    const addSnackbarQueue = useAddSnackbarQueue()    
    
    const  { addItem, getItemsMap } = useStoreHelpers()  
    const rolesMap = getItemsMap("roles")
    return (
        <Formik
            initialValues={{
                firstName: user ? user.firstName : '',
                lastName:  user ? user.lastName : '', 
                cellphone: user ? user.cellphone : '',
                email:  user ? user.email : '', 
                roles: user && user.roles ? user.roles.map(role => role.id) : []     
            }}
            validate={values => {
                const errors = {};
                if (!values.firstName) {
                    errors.firstName = 'Required';
                } 
                if (!values.lastName) {
                    errors.lastName = 'Required';
                }
                if (!values.email) {
                    errors.email = 'Required';
                }
                return errors;
            }}
            onSubmit={ async (values, { setSubmitting }) => {
                let newValues = {...values}
                newValues.cellphone = transformNumberTo10DigitFormat(values.cellphone)
                const [er,response] = user ? await api.put('/users/withRoles/'+user.id, {...newValues})  : await api.post('/users', {...newValues} ) 
                setSubmitting(false);                
                if (!er) {
                    addItem(response.data,"users")
                    addSnackbarQueue("User was " + (user ? "updated" : "created"))  
                    close()                    
                }else {
                    addSnackbarQueue("An error occurred " + (er.message),"error")  
                }
                return;
            }}    
        >
        {({ submitForm, isSubmitting, values }) => (
            <Form>
                <Field
                    component={TextField}
                    name="firstName"
                    type="text"
                    label="First Name"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginRight}
                />
                
                <Field
                    component={TextField}
                    name="lastName"
                    type="text"
                    label="Last Name"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginLeft}
                />
                <Field
                    component={TextField}
                    name="cellphone"
                    type="text"
                    label="Cellphone"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginLeft}
                />
                <br />
                <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                />
                <br /> 
                <Field
                    component={TextField}
                    select={true}
                    SelectProps={{
                        multiple: true,
                        renderValue : (selected) => (
                            <div className={chipClasses.chips}>
                              {selected.map((value) => (
                                <Chip key={value} label={rolesMap[value] ? rolesMap[value].name : ""} color="secondary" size="small" className={chipClasses.chip} />
                              ))}
                            </div>
                        )
                    }}
                    input={<Input id="select-multiple-chip" />}                    
                    name="roles"                    
                    label="Roles"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"                    
                >
                    {roles.map((role) => (
                            <MenuItem key={role.id} value={role.id} style={{fontWeight: values.roles && values.roles.indexOf(role.id) == -1  ? theme.typography.fontWeightRegular
                                : theme.typography.fontWeightBold}}>
                                {role.name}
                            </MenuItem>
                    ))}
                </Field>
                
                             
                {isSubmitting && <LinearProgress />}
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                >
                    Submit
                </Button>
            </Form>
        )}
    </Formik>
    )
}

export default UpsertForm