import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,  
  Menu,
  MenuItem 
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import {useApiHttpState} from '../../context/ApiContext'
//import api from '../../utils/api'

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch , useUserState} from "../../context/UserContext";


export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch(); 

  // local
  var [profileMenu, setProfileMenu] = useState(null);
  const {api} = useApiHttpState()
  const dispatch = useUserDispatch()
  const userData = useUserState()
  const user = userData.user ? userData.user : {}
  

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        {!props.loginHeader && <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>}
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          Admin Portal
        </Typography>
        <div className={classes.grow} />         
        {!props.loginHeader && <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton> }   
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h5" >
              {user.firstName} {user.lastName}
            </Typography>
            <br />
            <Typography variant="h5" className={classes.typo}>
              {user.email}
            </Typography>
          </div>      
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={async () => {
                const [er] = await api.get('/users/signOut')                
                if(!er) {
                  //signOut()
                  dispatch({ type: "SIGN_OUT_SUCCESS" })
                  props.history.replace('/login');
                }

              }}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
