import React from "react";

import CallLogTable from '../callLogs/Table'
import { useHouseholdCallogs } from "../../apis";

const HouseholdCallLogs = ({household}) => {    
    const householdId = household.id
    const {callLogs,isLoading,error} = useHouseholdCallogs(householdId) 

    return (
        <>
            <CallLogTable title="Call History" callLogs={callLogs} hideActions /*loadCallerCallLogs={loadCallerCallLogs}*/ />
        </>
    )
}

export default HouseholdCallLogs