import React from "react";
import {
  Route,
  Switch,
  //Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header/Header";
//import Sidebar from "../Sidebar/Sidebar";

// pages

import Error from "../../pages/error";
import Register from "../../pages/login/Register.js";
import ResetPassword from "../../pages/login/ResetPassword.js";
import Login from "../../pages/login";

// context
import { useLayoutState } from "../../context/LayoutContext";

const PublicLayout = (props) => {
  var classes = useStyles();
  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} loginHeader /> 
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/login" component={Login} />       
              <Route path="/register" component={Register} /> 
              <Route path="/resetPassword/:token?" component={ResetPassword} />
              <Route component={Error} />
            </Switch> 
            </div>
        </>
    </div>
  );
}

export default withRouter(PublicLayout);
