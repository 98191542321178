import React, { useEffect, useState } from "react";
import dateFormat from 'date-fns/format'
import MUIDataTable from "../../components/DataTable"
import AddToolButton from "../../components/DataTable/AddToolButton"
import { Button } from "../../components/Wrappers";
import { useHelperStyles } from '../../styles'
import {  useOpenConfirmationDialog, useCloseDialog } from "../../context/DialogContext";
import { useApiHttpState } from '../../context/ApiContext'
import { useAddSnackbarQueue } from "../../context/SnackbarContext";
import { useStoreHelpers } from '../../store'
import { DropzoneDialog } from "material-ui-dropzone";

const Documents = ({caseId,householdId,documents,users,reloadHouseholdCase}) => {
    const helperClasses = useHelperStyles() 
    const [open, setOpen] = useState(false)
    const openConfirmationDialog = useOpenConfirmationDialog()
    const closeConfirmationDalog = useCloseDialog()
    const { api } = useApiHttpState()
    const addSnackbarQueue = useAddSnackbarQueue()
    const { addItem } = useStoreHelpers()  
    const openDropzoneDialog = () => {
        setOpen(true)
    }
    const closeDropzoneDialog = () => {
        setOpen(false)
    }
    const handleSaveDocument = async (props) => {      
      let formData = new FormData();
      formData.append("householdId", householdId);
      props.forEach(file => formData.append("documents", file))            
      const [er,response] = await api.post(`/householdCases/${caseId}/documents`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          useLoader:false
      })
      if(!er) {
        addItem(response.data,"householdCases")
        addSnackbarQueue(`${props.length} document${!!props.length > 1 ? "s" : ""} uploaded`)
      } else {
        addSnackbarQueue("An error occurred","error")
      }
      closeDropzoneDialog()
    }
    const handleDeleteDocument = async document => {
      const [er,response] = await api.del(`/documents/${document.id}`)
      if(!er) {
        await reloadHouseholdCase()
        closeConfirmationDalog()
        addSnackbarQueue(`Document deleted`)
      } else {
        addSnackbarQueue("An error occurred","error")
      }
    }
    const handleClickDownload = async document => {      
      const [er,response] = await api.get(`/documents/${document.id}/getLink`,{useLoader:true})
      if(!er) { 
        const link =response.data.link;
        if(link) window.open(link,"_blank")
      } else {
        addSnackbarQueue("An error occurred","error")
      }
    }
    const columns = [      
        {name : "fileName", label: "File Name"},
        {name : "createdAt", label: "Created At",options: {
          customBodyRenderLite: (dataIndex) => {
            const row = documents[dataIndex]; 
            return (
              <div>
                {dateFormat(new Date(row.createdAt),"MMM do, h:mma")}
              </div>
            );
          }
        }},        
        {name : "createBy", label: "Created By", options: {
          customBodyRenderLite: (dataIndex) => {
            const row = documents[dataIndex]; 
            return (
              <div>
                {users && users[row.createdBy] ? users[row.createdBy].email : ""}
              </div>
            );
          }
        } },
        
        {name: " ", options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              const row = documents[dataIndex]; 
              return (
                <div className={helperClasses.floatRight}>
                  <Button variant="contained" size="small" color="success" className={helperClasses.marginLeft} onClick={() => handleClickDownload(row)}>Download</Button>
                  <Button variant="contained" size="small" color="secondary" className={helperClasses.marginLeft} onClick={() => openConfirmationDialog("Are you sure you want to delete the " + row.fileName + " file?" , () => handleDeleteDocument(row)  ,"Confirm Delete" )}>Delete</Button>
                </div>
              );
            }
          }
        },
    ]
    const options = {
        customToolbar: () => {
            return (
                <AddToolButton handleClick={openDropzoneDialog}  />
            );
        }
    }
    return (
        <>
        <DropzoneDialog 
            open={open}
            onSave={handleSaveDocument}
            onClose={closeDropzoneDialog}        
            showFileNamesInPreview={true}
            useChipsForPreview={true}
            dialogTitle="Upload new document"
            />
          <MUIDataTable title={"Documents"} data={documents? documents : []} columns={columns} options={options} />
        </>
    )
}

export default Documents