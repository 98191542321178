import React, { useEffect, useState } from 'react';

import { Grid,Box } from "@material-ui/core";
import { Button } from "../../../components/Wrappers";
import dateFormat from 'date-fns/format'

import { differenceInCalendarYears, parse } from "date-fns"

import MUIDataTable from "../../../components/DataTable"
import AddToolButton from "../../../components/DataTable/AddToolButton"
import {useApiHttpState} from '../../../context/ApiContext'
import { useAddSnackbarQueue} from "../../../context/SnackbarContext";
import { useOpenDialog , useOpenConfirmationDialog, useCloseDialog } from "../../../context/DialogContext";
import { useStoreHelpers } from '../../../store'

import PageTitle from "../../../components/PageTitle";
import UpsertForm from './UpsertForm'


  export default function Volunteers() { 
    const  {getItemsArray : getItems , addItems, addItem } = useStoreHelpers()  
    const openDialog = useOpenDialog()
    const openConfirmationDialog = useOpenConfirmationDialog()
    const close = useCloseDialog()
    const addSnackbarQueue = useAddSnackbarQueue() 
    const {loading,api} = useApiHttpState()
    const loadData = async () => {
      const [er,response] = await api.get('/volunteers',{useLoader:true})
      if(!er) {
        addItems(response.data,"volunteers")
      }      
    }  
    
    const volunteers = getItems("volunteers")
    
    const toggleVolunteerStatus = async (volunteer) => {
      const [er,response] = await api.put(`/volunteers/${volunteer.id}`,{active: !volunteer.active ? 1 : 0})
      if(!er) {
        addItem(response.data,"volunteers")   
        close()     
        addSnackbarQueue(`The volunteer ${volunteer.firstName} ${volunteer.lastName} was ${!volunteer.active ? "enabled" : "disabled"} successfully.`,"success")  
      } else {
        addSnackbarQueue("An error occurred " + (er.message),"error")  
      }
    }

    useEffect(() => {
      loadData()      
    },[])
    //dateFormat(new Date(row.startedAt),"MMM do, h:mm:ssa")
    const columns = [ 
      {name : "firstName", label: "First Name"}, 
      {name : "lastName", label: "Last Name"}, 
      {name : "email", label: "Email"}, 
      {name : "cellphone", label: "Cellphone"},
      {name : "gender", label: "Gender"},
      {name : "dob", label: "Age", options: { customBodyRender: (value) => value ? differenceInCalendarYears(new Date(), new Date(value)) : "" } },
      {name : "active", label: "Active", options: { customBodyRender: (value) => !!value ? "Active" : "Inactive" }}, 
      {name : "notes", label: "Notes"},
      {name : "address", label: "Address",options:{display:false}},
      {name : "city", label: "City",options:{display:false}},
      {name : "state", label: "State",options:{display:false}},
      {name : "created_at", label: "Create Date", options: {        
        customBodyRenderLite : (dataIndex) => {
          const row = volunteers[dataIndex];
          return dateFormat(new Date(row.created_at),"MMM do, yyyy")
        }
      }},           
      {name: "", options: {
      filter: false,
      sort: false,
      empty: true,      
      customBodyRenderLite: (dataIndex) => {
        const row = volunteers[dataIndex]; 
        return (
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" size="small" color="warning" margin onClick={() => openDialog("Edit Volunteer" , <UpsertForm volunteer={row} /> )} >Edit</Button>
            {!!row.active && <Button variant="contained" size="small" color="error" margin onClick={() => openConfirmationDialog("Are you sure you want to disable " + row.firstName + " " + row.lastName + " ?" , () => toggleVolunteerStatus(row)  ,"Confirm Disabling" )} >Disable</Button>}
            {!row.active && <Button variant="contained" size="small" color="info" margin onClick={() => openConfirmationDialog("Are you sure you want to enable " + row.firstName + " " + row.lastName + " ?" , () => toggleVolunteerStatus(row)  ,"Confirm Enabling" )} >Enable</Button>}
          </Box>
        );
      }
    }
  }];
  const options = {
    viewColumns:true,
    selectableRows:"none",
    customToolbar: () => {
        return (
            <AddToolButton handleClick={() => openDialog("Add New Volunteer" , <UpsertForm /> )}  />
        );
    }
  }
    return (
      <>
        <PageTitle title="Volunteers" />
        <Grid container spacing={4}>
          <Grid item xs={12}>            
              {!loading && <MUIDataTable data={volunteers ? volunteers : []} columns={columns} options={options}  />}            
          </Grid>
        </Grid>
      </>
    );
  }