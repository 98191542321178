import { makeStyles } from "@material-ui/core/styles";


const useHelperStyles = makeStyles(theme => ({
    floatLeft: {
        float: "left"
    },
    floatRight: {
        float: "right"
    },
    xsMargin: {      
        margin: theme.spacing(.5)     
    },
    smMargin: {      
        margin: theme.spacing(.75)     
    },
    margin: {      
        margin: theme.spacing(1)     
    },
    paperMargin: {      
        margin: theme.spacing(3)     
    },
    paperPadding: {
        padding: theme.spacing(2)
    },
    itemPadding: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    marginTopBottom: {      
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)       
    },
    xsMarginLeft: {      
        marginLeft: theme.spacing(.5)     
    },
    smMarginLeft: {      
        marginLeft: theme.spacing(.75)     
    },
    marginLeft: {      
        marginLeft: theme.spacing(1)     
    },
    xsMarginRight: {      
        marginRight: theme.spacing(.5)     
    },
    smMarginRight: {      
        marginRight: theme.spacing(.75)     
    },
    marginRight: {      
        marginRight: theme.spacing(1)     
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    }
}));

export default useHelperStyles