import React, { useEffect, useState } from 'react'
import { Formik, Form, Field,FieldArray  } from 'formik';
import { TextField } from 'formik-material-ui';

import { makeStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper';


import { Button, FormikOnChangeTextField } from "../../components/Wrappers";
import { useAddSnackbarQueue} from "../../context/SnackbarContext";
import { useCloseDialog } from "../../context/DialogContext";
import { useStoreHelpers } from '../../store'
import { useHelperStyles } from '../../styles'
import {useApiHttpState} from '../../context/ApiContext'
import {getFullName} from '../../utils'
import {transformNumberTo10DigitFormat} from '../../utils/common'


const useStyles = makeStyles(theme => ({
    center: {
        margin : "0 auto"
    },
    twoThirdsWidth: {
        width: "66%"
    },
    listHeader: {
        color: "#fff",
        backgroundColor: "rgb(0 0 0 / 20%)"
    }
}))

const UpsertForm = ({household, phoneNumber,mutate,history}) => {
    const [similarHouseholds,setSimilarHouseholds] = useState()
    const close = useCloseDialog() 
    const { api } = useApiHttpState()    
    const addSnackbarQueue = useAddSnackbarQueue()    
    const helperClasses = useHelperStyles() 
    const classes = useStyles()   
    const { getItemsArray : getItems , addItems , addItem } = useStoreHelpers()  
    
    const loadPhoneNumberTypes = async  () => {
        const [er,response] = await api.get('/phoneNumberTypes/',{useLoader:true})        
        if (!er) addItems(response.data,"phoneNumberTypes")        
    }
    useEffect(() => {
        loadPhoneNumberTypes()
    },[])
    const phoneNumberTypes = getItems("phoneNumberTypes");
    const households = getItems("households")
    const handleNameChange = (event,formProps) => {        
        if(!household) { //only want to check when creating a new one. (if it becomes an issue of someone adding a phone number to an existing house that is from someone else we will need to adjust for that)
            const {  
                form: { setValues, values },                      
                field: { name },
            } = formProps;
            const { value } = event.target;
            
            let currentValues = Object.assign({},values)
            currentValues[name] = value
            const {familyName,husbandName,wifeName} = currentValues            
            const similarHouseholdsFilter = households.filter(household => {
                let matches =  false 
                matches = (household.familyName.toLowerCase() == familyName.toLowerCase())
                matches = (!!household.husbandName && !!husbandName && household.husbandName.toLowerCase() == husbandName.toLowerCase() || (!husbandName && !!matches) )                       
                matches = (!!household.wifeName && !!wifeName && household.wifeName.toLowerCase() == wifeName.toLowerCase() || (!wifeName && !!matches))
                return matches
            })
            setSimilarHouseholds(similarHouseholdsFilter)
        } else {
            setSimilarHouseholds(null)
        }
    }
    
    return (
        <Formik
            initialValues={{
                familyName: household ? household.familyName : '',
                husbandName: household ? household.husbandName : '',
                wifeName: household ? household.wifeName : '',
                address: household ? household.address : '',
                city: household ? household.city : '',
                state: household ? household.state : '',
                postalCode: household ? household.postalCode : '',
                phoneNumbers: household && household.phoneNumbers.length ? household.phoneNumbers : [{phoneNumber:phoneNumber?phoneNumber:"",phoneTypeId:null}],
                emails: household && household.emails.length ? household.emails : [{email:""}]                 
            }}
            validate={values => {
                const errors = {};
                if (!values.familyName) {
                    errors.familyName = 'Required';
                } 
                //needs at least one phone number
                return errors;
            }}
            onSubmit={ async (values, { setSubmitting }) => {
                //might want to check here before submitting that there isn't a match. THe issue is if there is a false positive how to deal with it.
                const newValues = Object.assign({},values)

                newValues.phoneNumbers = newValues.phoneNumbers.map(phoneNumber => {
                    phoneNumber.phoneNumber =  transformNumberTo10DigitFormat(phoneNumber.phoneNumber)
                    delete phoneNumber.createdAt
                    delete phoneNumber.updatedAt
                    delete phoneNumber.phoneType
                    return phoneNumber
                }) .filter(phoneNumber => !!phoneNumber.phoneNumber)
                newValues.emails = newValues.emails.map(email => {
                    delete email.createdAt
                    delete email.updatedAt
                    return email
                }).filter(email => !!email.email)
                const [er,response] = household || newValues.id ? await api.put('/households/'+(household ? household.id : newValues.id), {...newValues} )  : await api.post('/households', { ...newValues } )
                setSubmitting(false);                
                if (!er) {
                    if(household && mutate) mutate(response.data)
                    //addItem(response.data,"households")
                    addSnackbarQueue("Household was " + (household ? "updated" : "created")) 
                    close()                  
                    //go to houshold page if not already there 
                    if(!household && history) history.push('/app/household/'+ response.data.id)   
                } else {
                    addSnackbarQueue("An error occurred","error")
                }
                return;
            }}    
        >
        {({ submitForm,values,setValues, isSubmitting }) => {

            const chooseHousehold = (household) => {
                const newValues = {...household}
                newValues.phoneNumbers = [...newValues.phoneNumbers,...values.phoneNumbers]
                setValues(newValues)
            }
            
            return (           
            <Form>                
                <Field
                    component={FormikOnChangeTextField}
                    name="familyName"
                    type="text"
                    label="Family Name"
                    variant="outlined"
                    size="small"
                    margin="normal"
                    className={helperClasses.marginRight}
                    onChange={handleNameChange}
                />                  
                <Field
                    component={FormikOnChangeTextField}
                    name="husbandName"
                    type="text"
                    label="Husband's Name"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginRight}
                    onChange={handleNameChange}
                />    
                <Field
                    component={FormikOnChangeTextField}
                    name="wifeName"
                    type="text"
                    label="Wife's Name"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginRight}
                    onChange={handleNameChange}
                /> {!!similarHouseholds && !!similarHouseholds.length && !values.id && 
                    <Paper elevation={3} className={classes.center + " " + classes.twoThirdsWidth} >
                        <List 
                            dense 
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader" disableSticky className={classes.listHeader}>
                                    Possible Matches - Choose from the list
                                </ListSubheader>
                            }
                        >
                            <Divider />
                            {similarHouseholds.map(household => (
                                    <ListItem divider >
                                        <ListItemText primary={getFullName(household)}  secondary={`${household.address} ${household.city},${household.state} ${household.postalCode}`} />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="select" onClick={() => chooseHousehold(household)}>
                                                <AssignmentTurnedInIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            )}
                            
                        </List>
                    </Paper>
                }  
                <br />
                <Field
                    component={TextField}
                    name="address"
                    type="text"
                    label="Street"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    fullWidth
                    className={helperClasses.marginRight}
                />   
                <br />  
                <Field
                    component={TextField}
                    name="city"
                    type="text"
                    label="City"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginRight}
                />    
                <Field
                    component={TextField}
                    name="state"
                    type="text"
                    label="State"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginRight}
                />
                <Field
                    component={TextField}
                    name="postalCode"
                    type="text"
                    label="Postal Code"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginRight}
                />   
                <br />
                <div>Phone Numbers</div>
                <FieldArray 
                    name="phoneNumbers"
                    render={arrayHelpers => {
                        return values.phoneNumbers.map((phoneNumber, index) => (
                            <div key={index}>
                              <Field 
                                component={TextField}
                                name={`phoneNumbers.[${index}].phoneNumber`} 
                                label="Phone Number"
                                variant="outlined"
                                margin="normal"
                                size="small"
                                className={helperClasses.marginRight}
                              />
                              <Field
                                component={TextField}
                                select={true}       
                                name={`phoneNumbers.[${index}].phoneTypeId`}                    
                                label="Phone Type"                                
                                variant="outlined"
                                margin="normal"
                                size="small" 
                                className={helperClasses.marginRight}  
                                style={{width:"15em"}}                 
                                >
                                    <MenuItem key={0} value={null}>
                                        Phone Number Type
                                    </MenuItem> 
                                    {!!phoneNumberTypes && phoneNumberTypes.map((phoneNumberType) => (
                                            <MenuItem key={phoneNumberType.id} value={phoneNumberType.id}>
                                                {phoneNumberType.name}
                                            </MenuItem>
                                    ))}
                                </Field>
                              
                              <span style={{marginTop: "16px", marginBottom: "8px",display:"inline-flex"}}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    className={helperClasses.marginRight} 
                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                >
                                    -
                                </Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => arrayHelpers.insert(index + 1, '')} // insert an empty string at a position
                                >
                                    +
                                </Button>

                              </span>
                            </div>
                          ))
                       
                    }}
                />
                <div>Email</div>
                <FieldArray 
                    name="emails"
                    render={arrayHelpers => {
                        return values.emails.map((email, index) => (
                            <div key={index}>
                              <Field 
                                component={TextField}
                                name={`emails.[${index}].email`} 
                                label="Email Address"
                                variant="outlined"
                                margin="normal"
                                size="small"
                                className={helperClasses.marginRight}
                              />                                                            
                              <span style={{marginTop: "16px", marginBottom: "8px",display:"inline-flex"}}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    className={helperClasses.marginRight} 
                                    onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                >
                                    -
                                </Button>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => arrayHelpers.insert(index + 1, '')} // insert an empty string at a position
                                >
                                    +
                                </Button>

                              </span>
                            </div>
                          ))
                       
                    }}
                />
                {isSubmitting && <LinearProgress />}
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                >
                    Submit
                </Button>
            </Form>
        )}
        }
    </Formik>
    )
}

export default UpsertForm