const genderOptions = [
    {value:"",label:"Choose Option"},
    {value:"Male"},
    {value:"Female"}
]

const yesNoOptions = [
    {value:"",label:"Choose Option"},
    {value:"Yes"},
    {value:"No"},
]

const MentalHealthFields = {
    name:{default:"",label:"Name",width:4},
    dateOfBirth:{default:"",label:"Date Of Birth",width:4,componentType:"KeyboardDatePicker",format:"MM/dd/yyyy",variant:"inline"},
    gender:{default:"",label:"",width:4,select:true,options:genderOptions},

    callerName:{default:"",label:"",width:4}, 
    callerNumber:{default:"",label:"",width:4},
    callerRelation:{default:"",label:"",width:4},     
    
    doctorReferredTo:{default:"",label:"Refferred To",width:8,placeholder:"Doctor..."},
    type:{default:"",label:"",width:4}, 
    
    notes:{default:"",label:"",width:12,multiline:true,rows:4},
    followUpNotes:{default:"",label:"",width:12,multiline:true,rows:4}
}

export default MentalHealthFields