import React, { useEffect, useState } from 'react';

import { Grid,Box } from "@material-ui/core";
import { Button } from "../../../components/Wrappers";
import Chip from '@material-ui/core/Chip';

import MUIDataTable from "../../../components/DataTable"
import {useApiHttpState} from '../../../context/ApiContext'
import { useAddSnackbarQueue} from "../../../context/SnackbarContext";
import { useOpenDialog , useOpenConfirmationDialog, useCloseDialog } from "../../../context/DialogContext";
import { useStoreHelpers } from '../../../store'
import { useHelperStyles } from '../../../styles'

import PageTitle from "../../../components/PageTitle";
import UpsertForm from './UpsertForm'


  export default function Users() { 
    const  {getItemsArray : getItems , addItems, addItem } = useStoreHelpers()  
    const openDialog = useOpenDialog()
    const openConfirmationDialog = useOpenConfirmationDialog()
    const helperClasses = useHelperStyles()
    const close = useCloseDialog()
    const addSnackbarQueue = useAddSnackbarQueue() 
    const {loading,api} = useApiHttpState()
    const loadData = async () => {
      const [er,response] = await api.get('/users/withRoles',{useLoader:true})
      if(!er) {
        addItems(response.data,"users")
      }      
    }  
    const loadRolesData = async () => {
      const [er,response] = await api.get('/roles/',{useLoader:true})
      if(!er) {
        addItems(response.data,"roles")
      }      
    }  
    const users = getItems("users")
    const roles = getItems("roles")
    const toggleUserStatus = async (user) => {
      const [er,response] = await api.put(`/users/${user.id}`,{active: !user.active ? 1 : 0})
      if(!er) {
        addItem(response.data,"users")   
        close()     
        addSnackbarQueue(`The user ${user.email} was ${!user.active ? "enabled" : "disabled"} successfully.`,"success")  
      } else {
        addSnackbarQueue("An error occurred " + (er.message),"error")  
      }
    }

    useEffect(() => {
      loadData()
      loadRolesData()
    },[])
    
    const columns = [ "email", "firstName", "lastName", "cellphone",
      {name:"roles", options:{
        customBodyRenderLite: (dataIndex) => {
          const row = users[dataIndex]; 
          return (
            <Box display="flex" flexWrap="wrap" >
              {row.roles.map((role) => (
                <Chip key={role.id} label={role.name} color="secondary" size="small" className={helperClasses.marginRight} />
              ))}
            </Box>
          );
        }
        
      }},
      {name: "", options: {
      filter: false,
      sort: false,
      empty: true,
      customBodyRenderLite: (dataIndex) => {
        const row = users[dataIndex]; 
        return (
          <Box display="flex" justifyContent="flex-end">
            <Button variant="contained" size="small" color="warning" margin onClick={() => openDialog("Edit User" , <UpsertForm user={row} roles={roles} /> )} >Edit</Button>
            {!!row.active && <Button variant="contained" size="small" color="error" margin onClick={() => openConfirmationDialog("Are you sure you want to disable " + row.email + " ?" , () => toggleUserStatus(row)  ,"Confirm Disabling" )} >Disable</Button>}
            {!row.active && <Button variant="contained" size="small" color="info" margin onClick={() => openConfirmationDialog("Are you sure you want to enable " + row.email + " ?" , () => toggleUserStatus(row)  ,"Confirm Enabling" )} >Enable</Button>}
          </Box>
        );
      }
    }
  }];
    return (
      <>
        <PageTitle title="Users" />
        <Grid container spacing={4}>
          <Grid item xs={12}>            
              {!loading && <MUIDataTable data={users ? users : []} columns={columns} />}            
          </Grid>
        </Grid>
      </>
    );
  }