import React, { useEffect, useState } from "react";
//import {useKnowledgeBaseStore} from '../../store'

import {useApiHttpState} from '../../context/ApiContext'
//import useFetch from 'use-http'
import MUIDataTable from 'mui-datatables'
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import AddIcon from '@material-ui/icons/AddToPhotos';
import PageTitle from "../../components/PageTitle";
import { Button } from "../../components/Wrappers";
import CustomToolButton from "../../components/MUIDataTable/CustomToolButton"
import useStyles from "./styles";
import Form from './Form'
import DeleteConfirmation from './DeleteConfirmation'
import { format } from 'date-fns'

import {
    Grid
} from "@material-ui/core";

import { useOpenDialog , useCloseDialog, useOpenSmsDialog} from "../../context/DialogContext";
import { useStoreHelpers } from '../../store'


const KnowledgeBase = (props) => {
    const {loading,api} = useApiHttpState()
    const  {getItemsArray : getItems , addItems } = useStoreHelpers("knowledgeBase")  
    const openDialog = useOpenDialog()
    const closeDialog = useCloseDialog()
    const openSmsDialog = useOpenSmsDialog()
    
    const knowledgeBaseItems = getItems(); 
     
    const [smsTelNumber,setSmsTelNumber] = useState("");

    const openEdit = (item) => {       
        openDialog(`${!!item ? "Edit" : "New"} Knowledge Base Item`,<Form item={item} close={closeDialog} addItems={addItems} />)
    }
    const confirmDelete = (item) => {
        openDialog("Delete Item",<DeleteConfirmation item={item} close={closeDialog} />)
    }
    const openSendSms = (item) => {
        openSmsDialog(smsTelNumber,`${!!item.question && item.question.toUpperCase()}\n${item.answer}`)
    }
    
    useEffect(() => {
        if(props.location.search) {
            const searchParams = new URLSearchParams(props.location.search);
            if(searchParams.has("fromNumber")) {
                setSmsTelNumber(searchParams.get("fromNumber"))
                searchParams.delete("fromNumber");  
                //window.history.replaceState(null, null, `#${props.location.pathname}${searchParams.toString() ? '?' + searchParams.toString() : ''}`)              
                //props.history.replace(`${props.location.pathname}${searchParams.toString() ? '?' + searchParams.toString() : ''}`)
            }            
        }
    },[])

    useEffect(() => {
        loadKnowledgeBase()
    },[])

    const classes = useStyles();
    
    const loadKnowledgeBase = async  () => {
        const [er,response] = await api.get('/knowledgeBase/',{useLoader:true})
        if(!er) {
            //maybe filterb
            addItems(response.data)
        }  
        //const knowledgeBaseItems = await get('/knowledgeBase')
        //if (response.ok) addKnowledgeBaseItems(knowledgeBaseItems) 

    }
    // add filter and use a memo function
    //const reviewedKnowledgeBaseItems = getItems();

    const options = {
        selectableRows:"none",
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = 3;
            const row = knowledgeBaseItems[rowMeta.dataIndex];            
            return (
              <TableRow>
                <TableCell colSpan={colSpan}>
                   {row.answer}
                </TableCell>
              </TableRow>
            );
        },
        sortOrder: {
            name: 'id',
            direction: 'desc'
        },
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,50,100],
        customToolbar: () => {
            return (
                <CustomToolButton toolTip="Add new topic" IconComp={AddIcon} handleClick={() => openEdit()}  />
            );
        }
    };

    const columns = [
        {name:"id" , options : {display: "excluded"}},
        {name : "question", label: "Topic"},
        {name : "dateCreated", label: "Added On", options : {
            customBodyRender: value =>  value ? format(new Date(value), "MM/dd/yyyy") : ""
        }},
        {name: " ", options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex) => {
              const row = knowledgeBaseItems[dataIndex]; 
              return (
                <div className={classes.floatRight}>
                  <Button variant="contained" size="small" color="success" className={classes.margin} onClick={() => openSendSms(row)} >Send SMS</Button>
                  <Button variant="contained" size="small" color="primary" className={classes.margin} onClick={() => openEdit(row)}>Edit</Button>
                  <Button variant="contained" size="small" color="error" className={classes.margin} onClick={() => confirmDelete(row)}>Delete</Button>
                </div>
              );
            }
          }
        }
    ];
    
    return (
        <>          
          <Grid container spacing={4}>  
            <Grid item xs={12}>
                {!!knowledgeBaseItems &&                    
                    <MUIDataTable title={"Knowledge Base"} data={knowledgeBaseItems} columns={columns} options={options} />                    
                }
            </Grid>            
          </Grid>
        </>
      );
}

export default KnowledgeBase