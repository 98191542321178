import React from "react";
import axios from 'axios'
import { useCacheState } from './CacheContext';
//import api from '../utils/api'

const ApiHttpStateContext = React.createContext();



const ApiHttpProvider = ({ children }) => {
    const { get, set, update } = useCacheState()
    const [loading, setLoading] = React.useState(false)
    const [api,setApi] = React.useState()
    
    React.useEffect(() => {
        const instance = axios.create({
            baseURL: `${window.location.origin}/api/v1`,
            withCredentials: true,
            useLoader: false,
            xsrfCookieName: 'csrf-token'
            //timeout: 1000,
            //headers: {'X-Custom-Header': 'foobar'}
        });
        instance.interceptors.request.use(async config => {                       
            if(config.useLoader) setLoading(true)
            if(!!config.useCache && config.method == 'get') {

                //check if cached - if so add filter to only request anything after cache
                let cachedData = await get( config.useCache.key )
                if(cachedData) {
                    const dateField = config.useCache.dateField
                    const sortedData =  cachedData.sort((a,b) => new Date(b[dateField]) - new Date(a[dateField]))
                    const newestUpdate = sortedData[0][dateField]
                    config.params = Object.assign({[dateField]:newestUpdate},...config.params) 
                }
            }
            return config;
        }, function (error) {
            return Promise.reject(error);
        });
    
        instance.interceptors.response.use(function (response) {    
            if(response.config && response.config.useLoader) setLoading(false)
            //console.log(response)
            return [null,response];
        }, function (error,response) {
            console.log(error)
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            setLoading(false)
            return [error,null]
        });
        
        setApi(() => instance)
    },[])

    

    return (
        <ApiHttpStateContext.Provider value={{loading,api}}>
            {!!api && children}
        </ApiHttpStateContext.Provider>
    );
}

const useApiHttpState = () => {
    var context = React.useContext(ApiHttpStateContext);
    if (context === undefined) {
        throw new Error("useLayoutState must be used within a LayoutProvider");
    }
    return context;
}

export {ApiHttpProvider,useApiHttpState}