import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    margin: {
        margin: theme.spacing(.5),
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    floatRight: {
        float: "right"
    },
    textCenter: {
        textAlign: "center"
    }
  }));
  