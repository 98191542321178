//import { yesNoOptions } from './caseInfoFieldsMetaData'

const maritalOptions = [
    {value:"",label:"Choose Option"},
    {value:"Married"},
    {value:"Single"},
    {value:"Divorced"},
]
const yesNoOptions = [
    {value:"",label:"Choose Option"},
    {value:"Yes"},
    {value:"No"},
]

const financialCounselingFields = {
    name:{default:"",label:"Name",width:4},
    homePhone:{default:"",label:"Home Phone",width:4},
    cellPhone:{default:"",label:"Cell Phone",width:4},
    
    homeAddress:{default:"",label:"Home Address",width:12},

    maritalStatus:{default:"",label:"Marital Status",width:4,select:true,options:maritalOptions},
    employmentOfCaller:{default:"",label:"Employment Of Caller",width:4},
    employmentOfSpouse:{default:"",label:"Employment Of Spouse",width:4},

    currentReasonForCall:{default:"",label:"",helperText:"Is there a particular reason they are calling now?",width:12},
    
    shulAffiliation:{default:"",label:"Shul Affiliation",width:6},
    shulRabbi:{default:"",label:"Shul Rabbi",width:6},

    eligibilityForPrograms:{default:"",label:"",width:4,helperText:"(i.e. Food Stamps, WIC, Medicaid)"},
    receivingOtherAssistance:{default:"",label:"",width:4,helperText:"(i.e. other organizations or indivduals)"},    
    budgetOnOwn:{default:"",label:"",helperText:"Have you ever budgeted on yur own?",width:4,select:true,options:yesNoOptions},

    availableTimeForCounseling:{default:"",label:"",width:12,helperText:"When can you meet with a counselor?"},
}

export default financialCounselingFields

