import React, { useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik ,Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  Box,    
  Container,  
  Link,  
  Typography,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Button, Form } from "../../components/Wrappers";
import { useHelperStyles } from '../../styles'
import Page from '../../components/Page';
import { useApiHttpState } from '../../context/ApiContext'
import { useUserState } from "../../context/UserContext";
import {transformNumberTo10DigitFormat} from '../../utils/common'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Register = () => {
  const classes = useStyles();
  const history = useHistory();  
  const {api} = useApiHttpState()
  const helperCalsses = useHelperStyles()
  var { isAuthenticated } = useUserState(); 
  useEffect(() => {
    if(isAuthenticated) {      
      history.replace('/app/dashboard');       
    }
  },[])
  return (
    <Page      
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              lastName: '',
              password: '',
              passwordConfirm: '',
              cellphone: ''              
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                firstName: Yup.string().max(255).required('First name is required'),
                lastName: Yup.string().max(255).required('Last name is required'),
                password: Yup.string().max(255).required('password is required'),
                passwordConfirm: Yup.string().max(255).required('Confrm password is required').oneOf([Yup.ref('password'), null], 'Passwords must match'),                
                cellphone: Yup.string().max(15).required('Cellphone is required'),
              })
            }
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              setSubmitting(true)
              setStatus("")
              let newValues = {...values}
              newValues.cellphone = transformNumberTo10DigitFormat(values.cellphone)
              const [er,response] = await api.post('/users/register',{...newValues})
              if (!er) {                 
                  history.replace('/app/dashboard');                                     
              } else {
                setStatus("An error occured!")
              }
              setSubmitting(false);
          }}
          >
            {({ isSubmitting, status }) => (
              <Form>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Create new account
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use your email to create new account
                  </Typography>
                </Box>
                <Field
                    component={TextField}
                    name="firstName"                    
                    label="First name"
                    variant="outlined"
                    margin="normal"                                        
                    fullWidth
                /> 
                <Field
                    component={TextField}
                    name="lastName"                    
                    label="Last name"
                    variant="outlined"
                    margin="normal"                                        
                    fullWidth
                /> 
                <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email Address"
                    variant="outlined"
                    margin="normal"                                        
                    fullWidth
                /> 
                <Field
                    component={TextField}
                    name="password"
                    type="password"
                    label="Password"
                    variant="outlined"
                    margin="normal"                                        
                    fullWidth
                />   
                <Field
                    component={TextField}
                    name="passwordConfirm"
                    type="password"
                    label="Confirm Password"
                    variant="outlined"
                    margin="normal"                                        
                    fullWidth
                />     
                <Field
                    component={TextField}
                    name="cellphone"                    
                    label="Cellphone"
                    variant="outlined"
                    margin="normal"                                        
                    fullWidth
                />                         
                <Box my={2}>
                  {!!status &&
                     <Alert severity="error" variant="filled" className={helperCalsses.marginTopBottom}>{status}</Alert>
                  }
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Sign in
                  </Link>
                </Typography>
              </Form>
            )}
          </Formik>

        </Container>
      </Box>
    </Page>
  );
};

export default Register;