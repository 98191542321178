import {useState, useEffect, Fragment} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useSnackbarState, useRemoveSnackbarQueue } from "../SnackbarContext";

const useStyles = makeStyles( (theme) => {  
  return {
    close: {
      padding: theme.spacing(0.5),
    },
    info:{
      backgroundColor: theme.palette.info.main
    },
    success:{
      backgroundColor: theme.palette.success.main
    },
    warning:{
      backgroundColor: theme.palette.warning.main
    },
    primary:{
      backgroundColor: theme.palette.primary.main
    },
    secondary:{
      backgroundColor: theme.palette.secondary.main
    },
    error:{
      backgroundColor: theme.palette.error.main
    },
  }
});

const ConsecutiveSnackbars = () => {
  const snackbarQueue = useSnackbarState().queue
  const removeSnackbarQueue = useRemoveSnackbarQueue();
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackbarQueue.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackbarQueue[0] });      
      setOpen(true);
    }
  }, [snackbarQueue, messageInfo, open]);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
    removeSnackbarQueue();
  };

  const classes = useStyles();
  const snackbarColor = messageInfo && messageInfo.color ? messageInfo.color : "primary"
  return (
    <div>
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        onExited={handleExited}
      >
        <SnackbarContent 
          classes={{root: classes[snackbarColor]}}
          message={messageInfo ? messageInfo.message : undefined}
          action={
            <Fragment>
              {messageInfo && messageInfo.actionComponent}
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Fragment>
          }
        />
        
      </Snackbar>
    </div>
  );
}

export default ConsecutiveSnackbars
