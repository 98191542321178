import React from "react"
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const CustomToolButton = ({toolTip,handleClick,IconComp}) => {
    return (
        <React.Fragment>
            <Tooltip title={toolTip}>
            <IconButton  onClick={handleClick}>
                <IconComp />
            </IconButton>
            </Tooltip>
        </React.Fragment>
    )
}

export default CustomToolButton