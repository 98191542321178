Object.defineProperty(exports, "__esModule", {
	value: true
});

exports.transformNumberTo10DigitFormat = transformNumberTo10DigitFormat;

function transformNumberTo10DigitFormat(number) {
    number = (number === null || typeof(number) !== 'string') ? '' : number.trim();
    number = number.replace(/\D/g, '');//this will strip non numbers but leave the +
    var hasLeading1 =  number.charAt(0) == "1";
    var startAt =  hasLeading1 ? 1 : 0;
    var numberSliced = number.slice(startAt)
    return numberSliced.length == 10 ? "1"+numberSliced : numberSliced 
}