const genderOptions = [
    {value:"",label:"Choose Option"},
    {value:"Male"},
    {value:"Female"}
]

const yesNoOptions = [
    {value:"",label:"Choose Option"},
    {value:"Yes"},
    {value:"No"},
]

const isHidden = (name,values) => true

const MedicalReferralFields = {
    name:{default:"",label:"Name",width:4,isHidden},
    dateOfBirth:{default:"",label:"Date Of Birth",width:4,componentType:"KeyboardDatePicker",format:"MM/dd/yyyy",variant:"inline",isHidden},
    gender:{default:"",label:"",width:4,select:true,options:genderOptions,isHidden},

    primaryCarePhysician:{default:"",label:"",width:6,isHidden},
    chiefComplaint:{default:"",label:"",width:6,isHidden},

    primaryInsuranceProvider:{default:"",label:"",width:6,isHidden},
    secondaryInsuranceProvider:{default:"",label:"",width:6,isHidden},

    willingToPayOutOfPocket:{default:"",label:"",width:4,select:true,options:yesNoOptions,isHidden},
    
    doctorPrefrences:{default:"",label:"Doctor Preferences",width:8,placeholder:"Gender,Location,Jewish?",isHidden},
    
    specialNeeds:{default:"",label:"",width:6,isHidden},
    medicalHistory:{default:"",label:"",width:6,isHidden},
     
    notes:{default:"",label:"",width:12,multiline:true,rows:4}
}

export default MedicalReferralFields