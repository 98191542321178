import React from "react"
import MUIDataTable from "mui-datatables";

const DataTable = (props) => {
    const defaultOptions = {download:false,print:false,viewColumns:false,selectableRows:"none",rowsPerPageOptions:[5,10,25,100]}    
    const mergedOptions = Object.assign(defaultOptions,props.options)
    return (
        <MUIDataTable {...props} options={mergedOptions} />
    )
}

export default DataTable