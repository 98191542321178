import React from "react";
import {
  Route,
  Switch,
  //Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import ScrollToTop from "./ScrollToTop";
import ErrorBoundary from './ErrorBoundary'

// pages
//import Dashboard from "../../pages/dashboard";
import KnowledgeBase from "../../pages/knowledgeBase";
import CallLogs from "../../pages/callLogs";

import Household from "../../pages/household/Household";
import Households from "../../pages/household/Households";
import HouseholdCase from "../../pages/householdCases/HouseholdCase";
import HouseholdCases from "../../pages/householdCases/HouseholdCases";

import Users from "../../pages/admin/users"
import Roles from "../../pages/admin/roles"
import Volunteers from "../../pages/admin/volunteers"
import Error from "../../pages/error"; 

// context
import { useLayoutState } from "../../context/LayoutContext";
import { SocketProvider } from "../../context/SocketContext";

function Layout(props) {
  var classes = useStyles();
  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          
            <Header history={props.history} />
            <Sidebar />
            <div
              className={classnames(classes.content, {
                [classes.contentShift]: layoutState.isSidebarOpened,
              })}
            >
              <div className={classes.fakeToolbar} />
                <ErrorBoundary>
                  <ScrollToTop>
                    <Switch>
                        <Route path="/app/dashboard" render={(props) => (
                            <CallLogs {...props} isDashboard={true} />
                          )}  />       
                        <Route path="/app/users" component={Users} /> 
                        <Route path="/app/roles" component={Roles} />
                        <Route path="/app/volunteers" component={Volunteers} />
                        
                        <Route path="/app/knowledgeBase" component={KnowledgeBase} /> 
                        <Route path="/app/callLogs" component={CallLogs} /> 
                        <Route path="/app/households" component={Households} />
                        <Route path="/app/household/:householdId/householdCase/:caseId" component={HouseholdCase} />
                        <Route path="/app/household/:householdId" component={Household} />
                        <Route path="/app/householdCases" component={HouseholdCases} />
                        <Route component={Error} />
                    </Switch>     
                  </ScrollToTop>
                </ErrorBoundary>
            </div>
          
        </>
    </div>
  );
}

export default withRouter(Layout);
