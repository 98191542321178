import React from 'react'
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Button } from "../../../components/Wrappers";
import { useAddSnackbarQueue} from "../../../context/SnackbarContext";
import { useCloseDialog } from "../../../context/DialogContext";
import { useStoreHelpers } from '../../../store'
import {useApiHttpState} from '../../../context/ApiContext'

const UpsertForm = ({role}) => {
    const close = useCloseDialog() 
    const { api } = useApiHttpState()    
    const addSnackbarQueue = useAddSnackbarQueue()    
    
    const  { addItem } = useStoreHelpers("roles")  
    return (
        <Formik
            initialValues={{
                name: role ? role.name : ''                
            }}
            validate={values => {
                const errors = {};
                if (!values.name) {
                    errors.name = 'Required';
                } 
                return errors;
            }}
            onSubmit={ async (values, { setSubmitting }) => {
                const [er,response] = role ? await api.put('/roles/'+role.id, {name: values.name} )  : await api.post('/roles', {name: values.name } ) 
                setSubmitting(false);                
                if (!er) {
                    addItem(response.data)
                    addSnackbarQueue("Role was " + (role ? "updated" : "created"))  
                    close()                    
                }
                return;
            }}    
        >
        {({ submitForm, isSubmitting }) => (
            <Form>
                <Field
                    component={TextField}
                    name="name"
                    type="text"
                    label="Role Name"
                />
                <br />                
                {isSubmitting && <LinearProgress />}
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                >
                    Submit
                </Button>
            </Form>
        )}
    </Formik>
    )
}

export default UpsertForm