import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField';
import { Button } from "../../components/Wrappers";
import useStyles from "./styles";
//import {useKnowledgeBaseStore} from '../../store'
import { useStoreHelpers } from '../../store'
import { useAddSnackbarQueue} from "../../context/SnackbarContext";
import {useApiHttpState} from '../../context/ApiContext'
//import useFetch from 'use-http'


const Form = ({item,close}) => {
    const [question,setQuestion] = useState(item ? item.question : "")
    const [answer,setAnswer] = useState(item ? item.answer : "")
    const classes = useStyles();
    const {  addItems } = useStoreHelpers("knowledgeBase")
    const addSnackbarQueue = useAddSnackbarQueue()
    const {loading,api} = useApiHttpState()    

    const handleSubmit = async () => {
        const isNew = !item;
        const data = {question,answer};
        const [er,response] = isNew ? await api.post('/knowledgeBase', data) : await api.put(`/knowledgeBase/${item.id}`, data)
        if (!er) {
            addItems(response.data)
            addSnackbarQueue(`Item was ${isNew ? "created" : "updated"} successfully.`,"success")
        } else {
            addSnackbarQueue(`An error occurred`,"error")  
        }
        close()
    }
    return (
        <>
           <TextField label="Topic" variant="outlined" fullWidth className={classes.marginBottom} value={question} onChange={ e => setQuestion(e.target.value)} /> 
           <TextField label="Explanation" variant="outlined" fullWidth className={classes.marginBottom} multiline rows={4} value={answer} onChange={ e => setAnswer(e.target.value)} />
           <Button variant="contained" size="small" color="success" pending={loading} className={classes.floatRight} onClick={handleSubmit} >Save</Button>
        </>
    )
}

export default Form