import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Button } from '../../components/Wrappers/Wrappers'
import {useHelperStyles} from '../../styles'

const DialogConfirmContent = ({confirmationText,onSubmit}) => {
    const helperClasses = useHelperStyles()
    return (
        <>
            <Typography>
                {confirmationText}
            </Typography>
            <Button variant="contained" size="small" color="primary" className={helperClasses.floatRight + " " + helperClasses.margin} onClick={() => onSubmit()} >Submit</Button>
        </>
    )
} 

export default DialogConfirmContent