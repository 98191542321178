import useSWR from 'swr'
import axios from 'axios'


const instance = axios.create({
    baseURL: `${window.location.origin}/api/v1`,
    withCredentials: true,
    xsrfCookieName: 'csrf-token'
});

const fetcher = (...args) => instance(...args).then(res => res.data)

const useCallogs = (recent) => {
    const { data, error, isLoading, mutate } = useSWR(`/callLogs/${recent ? "recent":""}`, fetcher)   
    return { callLogs: data,isLoading,isError: error, mutate }
}

const useHouseholdCallogs = (householdId) => {
    const { data, error, isLoading } = useSWR(`households/${householdId}/callLogs`, fetcher)   
    return { callLogs: data,isLoading,isError: error }
}

const useHouseholdCases = (userOpenCasesView) => {
    const { data, error, isLoading } = useSWR(`householdCases${userOpenCasesView ? '/userOpenCases':''}`, fetcher)   
    return { householdCases: data ? data.map(hc => {
        let household = JSON.parse(hc.household)
        household = {...household,  emails:hc.emails ? JSON.parse(hc.emails) : [],phoneNumbers: hc.emails ? JSON.parse(hc.phoneNumbers) : []}
        return {...hc, volunteers: JSON.parse(hc.volunteers),  household}            
    }) : [],
    isLoading,isError: error }
}

const useHouseholdHouseholdCases = (householdId) => {
    const { data, error, isLoading } = useSWR(`/households/${householdId}/cases`, fetcher)   
    return { householdCases: data ? data.map(c => ({...c,volunteers:JSON.parse(c.volunteers)})) : [],isLoading,isError: error }
}

const useHouseholds = () => {
    const { data, error, isLoading } = useSWR(`/households/`, fetcher)   
    return { households: data ? data.map(household => ({...household,emails:JSON.parse(household.emails),phoneNumbers:JSON.parse(household.phoneNumbers)})) : [],isLoading,isError: error }
}
const useHousehold = (householdId) => {
    const { data, error, isLoading,mutate } = useSWR(`/households/${householdId}`, fetcher)   
    return { household: data ? data.map(h => ({...h,emails:JSON.parse(h.emails),phoneNumbers:JSON.parse(h.phoneNumbers)}))[0] : [],isLoading,isError: error, mutate }
}


  export {useCallogs,useHouseholdCallogs,useHouseholds,useHousehold,useHouseholdCases,useHouseholdHouseholdCases}