import React, { useEffect } from 'react'
import { Formik, Form, Field  } from 'formik';
import { TextField } from 'formik-material-ui';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import { Button , Typography, FormikOnChangeTextField} from "../../components/Wrappers";
import { useAddSnackbarQueue} from "../../context/SnackbarContext";
import { useCloseDialog } from "../../context/DialogContext";
import { useStoreHelpers } from '../../store'
import { useHelperStyles } from '../../styles'
import {useApiHttpState} from '../../context/ApiContext'
import { useUserState } from "../../context/UserContext";
import CaseInfoFields from './CaseInfoFields';
import {getDefaultValues} from './caseInfoFieldsMetaData'

const UpsertForm = ({householdCase,household,partialMode}) => {
    const close = useCloseDialog() 
    const { api } = useApiHttpState()    
    const addSnackbarQueue = useAddSnackbarQueue()    
    const helperClasses = useHelperStyles()    
    const { getItemsArray : getItems , addItems , addItem, getItemsMap } = useStoreHelpers()  
    const { user } = useUserState()
    const loadCaseTypes = async  () => {
        const [er,response] = await api.get('/caseTypes/',{useLoader:true})        
        if (!er) addItems(response.data,"caseTypes")        
    }
    const loadCaseStatuses = async  () => {
        const [er,response] = await api.get('/caseStatuses/',{useLoader:true})        
        if (!er) addItems(response.data,"caseStatuses")        
    }    
    const loadUsers = async  () => {
        const [er,response] = await api.get('/users/forAssigning',{useLoader:true})        
        if (!er) addItems(response.data,"users")//need to take into account the users page should not get overwritten        
    }
    const loadVolunteers = async  () => {
        const [er,response] = await api.get('/volunteers',{useLoader:true})        
        if (!er) addItems(response.data,"volunteers")        
    }
    useEffect(() => {
        loadCaseTypes()
        loadCaseStatuses()
        loadUsers()
        loadVolunteers()
    },[])
    const caseTypes = getItems("caseTypes");
    const caseStatuses = getItems("caseStatuses");
    const users = getItems("users")
    const volunteersArray = getItems("volunteers")
    const volunteersMap = getItemsMap("volunteers")
    const employees = users ? users.filter(user => user.roles.some(role => role.name == "Administrator" || role.name == "Employee")) : []
    const volunteers = volunteersArray ? volunteersArray.filter(volunteer => volunteer.active) : []    
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Formik
                initialValues={{
                    householdId: household ? household.id : null,
                    caseTypeId: householdCase ? householdCase.caseTypeId : '',
                    statusId: householdCase ? householdCase.statusId : '',
                    caseInfo: householdCase && householdCase.caseInfo ? JSON.parse(householdCase.caseInfo) : null,
                    assignedTo: householdCase ? householdCase.assignedTo : null,
                    //volunteerId: householdCase ? householdCase.volunteerId : '',
                    volunteers: householdCase ? householdCase.volunteers.map(volunteer => volunteer.id) : [],
                    escalationLevel: householdCase ? householdCase.escalationLevel : 1, 
                    confidential:  householdCase ? householdCase.confidential : 0,  //default this based on the case type setting            
                }}
                validate={values => {
                    const errors = {};
                    if (!values.statusId) {
                        errors.statusId = 'Required';
                    }
                    //run validator from inner form fields                 
                    return errors;
                }}            
                onSubmit={ async (values, { setSubmitting }) => {
                    //might want to check here before submitting that there isn't a match. THe issue is if there is a false positive how to deal with it.
                    const newValues = Object.assign({createdBy:user.id},partialMode == "details" ? {caseInfo: values.caseInfo} : values)
                    if(household && !newValues.householdId) newValues.householdId = household.id
                    newValues.caseInfo = JSON.stringify(newValues.caseInfo)
                    
                    if(partialMode == "main") delete newValues.caseInfo 
                    
                    const [er,response] = householdCase ? await api.put('/householdCases/'+householdCase.id, {...newValues} )  : await api.post('/householdCases', { ...newValues } )
                    setSubmitting(false);                
                    if (!er) {
                        addItem(response.data,"householdCases")
                        addSnackbarQueue("Household case was " + (householdCase ? "updated" : "created")) 
                        //go to houshold page if not already there 
                        close()                    
                    } else {
                        addSnackbarQueue("An error occurred","error")
                    }
                    return;
                }}    
            >
            {({ submitForm,values, isSubmitting }) => {
                const caseTypeId = values.caseTypeId ? values.caseTypeId : 0
                const hideVolunteers = [1,4].indexOf(caseTypeId) > -1
                return (
                <Form>
                    <Grid container spacing={0}>
                        <Grid item xs={12} className={helperClasses.itemPadding} >
                            {!householdCase && <Field
                                component={FormikOnChangeTextField}
                                select={true}       
                                name={`caseTypeId`}                    
                                label="Case Type"                                
                                variant="outlined"
                                margin="normal"
                                size="small"                                                   
                                fullWidth 
                                onChange={(event,formProps) => {
                                    const {
                                        form: { setFieldValue, values, initialValues },
                                        field: { name },
                                    } = formProps;
                                    const { value } = event.target;
                                    if(values[name] != value) setFieldValue('caseInfo', getDefaultValues(value));
                                }}               
                            >
                                {!!caseTypes && caseTypes/*.filter(caseType => [1,2,3,4,5].indexOf(caseType.id) > -1)*/.map((caseType) => (
                                    <MenuItem key={caseType.id} value={caseType.id}>
                                        {caseType.name}
                                    </MenuItem>
                                ))}
                            </Field>}
                        </Grid>
                        {partialMode != "details" && <>                        
                            <Grid item xs={6} md={4} className={helperClasses.itemPadding} >
                                <Field
                                    component={TextField}
                                    select={true}       
                                    name={`statusId`}                    
                                    label="Status"                                
                                    variant="outlined"
                                    margin="normal"
                                    size="small" 
                                    fullWidth                                  
                                >
                                    {!!caseStatuses && caseStatuses.map((caseStatus) => (
                                        <MenuItem key={caseStatus.id} value={caseStatus.id}>
                                            {caseStatus.statusName}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                            <Grid item xs={6} md={4} className={helperClasses.itemPadding} >
                                <Field
                                    component={TextField}
                                    select={true}       
                                    name={`escalationLevel`}                    
                                    label="Escalation"                                
                                    variant="outlined"
                                    margin="normal"
                                    size="small" 
                                    fullWidth                                 
                                >                    
                                    <MenuItem key={1} value={1}>
                                        Regular
                                    </MenuItem>
                                    <MenuItem key={2} value={2}>
                                        Urgent
                                    </MenuItem>                   
                                </Field>                        
                            </Grid>
                            <Grid item xs={6} md={4} className={helperClasses.itemPadding} >
                                <Field
                                    component={TextField}
                                    select={true}       
                                    name={`confidential`}                    
                                    label="Confidential"                                
                                    variant="outlined"
                                    margin="normal"
                                    size="small" 
                                    fullWidth                                 
                                >                    
                                    <MenuItem key={1} value={1}>
                                        Yes
                                    </MenuItem>
                                    <MenuItem key={2} value={0}>
                                        No
                                    </MenuItem>                   
                                </Field>                        
                            </Grid>
                            <Grid item xs={6} className={helperClasses.itemPadding} >
                                <Field
                                    component={TextField}
                                    select={true}       
                                    name={`assignedTo`}                    
                                    label="Assigned To"                                
                                    variant="outlined"
                                    margin="normal"
                                    size="small" 
                                    fullWidth                                 
                                >
                                    {!!employees && employees.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.firstName} {user.lastName}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>                            
                            {!hideVolunteers && <Grid item xs={6} className={helperClasses.itemPadding} >
                                <Field
                                    component={TextField}
                                    select={true}       
                                    name={`volunteers`}                    
                                    label="Volunteers"                                
                                    variant="outlined"
                                    margin="normal"
                                    size="small" 
                                    fullWidth                                        
                                    SelectProps={{
                                        multiple: true,
                                        renderValue : (selected) => (
                                            <div className={helperClasses.chips}>
                                              {selected.map((value) => {
                                                const volunteer = volunteersMap ? volunteersMap[value] : {}
                                                return (
                                                    <Chip key={value} label={volunteer.firstName + " " + volunteer.lastName} color="primary" size="small" className={helperClasses.chip} />
                                                )
                                                })}
                                            </div>
                                        )
                                    }}
                                    input={<Input id="select-multiple-chip-volunteers" />}                           
                                >
                                    {!!volunteers && volunteers.filter(volunteer => volunteer.active).map((volunteer) => (
                                        <MenuItem key={volunteer.id} value={volunteer.id}>
                                            {volunteer.firstName} {volunteer.lastName}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>}
                        </>}
                        
                        {!!values.caseTypeId && partialMode != "main" &&  <CaseInfoFields caseTypeId={values.caseTypeId} values={values} />}                       
                        
                    </Grid>                     
                    {isSubmitting && <LinearProgress />}
                    <br />
                    <Typography align="right">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            onClick={submitForm}
                            className={helperClasses.marginTopBottom}
                        >
                            {!!householdCase ? "Update" : "Submit"}
                        </Button>

                    </Typography>
                </Form>
            )}}
            </Formik>
        </MuiPickersUtilsProvider>
    )
}

export default UpsertForm