const genderOptions = [
    {value:"",label:"Choose Option"},
    {value:"Male"},
    {value:"Female"},
]

const needOptions = [
    {value:"",label:"Choose Option"},
    {value:"I.V."},
    {value:"Foley"},
    {value:"Injection"},
    {value:"Blood Draw"},
]

const ivOptions = [
    {value:"",label:"Choose Option"},
    {value:"Right Arm"},
    {value:"Left Arm"},
    {value:"Right Hand"},
    {value:"Left Hand"},
    {value:"Right Wrist"},
    {value:"Left Wrist"},
    {value:"Right SC"},
    {value:"Left SC"},
]

const injectionOptions = [
    {value:"",label:"Choose Option"},
    {value:"Right Arm"},
    {value:"Left Arm"},
    {value:"Subcuteneous"},
    {value:"Abdomen"}
]



const isHidden = (name,values) => {    
    if(name.indexOf("iv") == 0) {
        if(values.caseInfo.need == "I.V.") return false
    }
    if(name.indexOf("foley") == 0) {
        if(values.caseInfo.need == "Foley") return false
    }
    if(name.indexOf("injection") == 0) {
        if(values.caseInfo.need == "Injection") return false
    }
    if(name.indexOf("bloodDraw") == 0) {
        if(values.caseInfo.need == "Blood Draw") return false
    }

    return true
}
const stayAtHomeFields = {
    name:{default:"",label:"Name",width:4},
    dateOfBirth:{default:null,label:"",width:4,componentType:"KeyboardDatePicker",format:"MM/dd/yyyy",variant:"inline"},
    gender:{default:"",label:"",width:4,select:true,options:genderOptions},

    address:{default:"",label:"Address",width:12},

    primaryCarePhysician:{default:"",label:"",width:3},
    chiefComplaint:{default:"",label:"",width:5},
    careGiverGenderPreference:{default:"",label:"",width:4,select:true,options:genderOptions},
    
    medicalHistory:{default:"",label:"",width:12},

    need:{default:"",label:"",width:3,select:true,options:needOptions},
    
    ivDateTime:{default:null,label:"Date/Time",width:6,componentType:"DateTimePicker",isHidden:isHidden},
    ivPractitionerName:{default:"",label:"Practitioner Name",width:6,isHidden:isHidden},
    ivSite:{default:"",label:"Site",width:4,select:true,options:ivOptions,isHidden:isHidden},
    ivGauge:{default:"",label:"Gauge",width:4,isHidden:isHidden},
    ivSolution:{default:"",label:"Solution",width:4,isHidden:isHidden},

    foleyDateTime:{default:null,label:"Date/Time",width:4,componentType:"DateTimePicker",isHidden:isHidden},
    foleyPractitionerName:{default:"",label:"Practitioner Name",width:4,isHidden:isHidden},
    foleySize:{default:"",label:"Size",width:4,isHidden:isHidden},

    injectionDateTime:{default:null,label:"Date/Time",width:6,componentType:"DateTimePicker",isHidden:isHidden},
    injectionPractitionerName:{default:"",label:"Practitioner Name",width:6,isHidden:isHidden},
    injectionMedication:{default:"",label:"Medication",width:4,isHidden:isHidden},
    injectionDosage:{default:"",label:"Dosage",width:4,isHidden:isHidden},
    injectionExpirationDate:{default:null,label:"Expiration Date",width:4,isHidden:isHidden,componentType:"KeyboardDatePicker",format:"MM/dd/yyyy",variant:"inline"},
    injectionSite:{default:"",label:"Site",width:4,select:true,options:injectionOptions,isHidden:isHidden},

    bloodDrawDateTime:{default:null,label:"Date/Time",width:6,componentType:"DateTimePicker",isHidden:isHidden},
    bloodDrawPractitionerName:{default:"",label:"Practitioner Name",width:6,isHidden:isHidden},
    bloodDrawTotalTubes:{default:"",label:"# of Tubes",width:4,isHidden:isHidden},
    bloodDrawReason:{default:"",label:"Reason",width:4,isHidden:isHidden},
    bloodDrawSite:{default:"",label:"Site",width:4,select:true,options:ivOptions,isHidden:isHidden},

}

export default stayAtHomeFields