
const requestTypes = [
    {value:"In-Hospital Referral"},
    {value:"Hospital Transfer"},
    {value:"In-Hospital Counseling"},
    {value:"Hospital Insurance Issues",label:"Hospital Insurance Issues (i.e. coverage for procedures)"},
    {value:"Food Related Inquiries"},
    {value:"Visitation/Shabbos Accomodations"},
    {value:"Other"}
]

const isHidden = (name,values) => true

const HospitalSupportFields = {
    name:{default:"",label:"Name",width:6,isHidden},
    dateOfBirth:{default:"",label:"Date Of Birth",width:6,componentType:"KeyboardDatePicker",format:"MM/dd/yyyy",variant:"inline",mask:"__/__/____",isHidden},

    contactName:{default:"",label:"Contact Name",width:4,isHidden},
    contactNumber:{default:"",label:"Contact Phone Number",width:4,isHidden},
    contactRelation:{default:"",label:"Contact Relation",width:4,isHidden},

    currentHospital:{default:"",label:"Current Hospital",width:3,isHidden},
    locationInHospital:{default:"",label:"Location In Hospital (i.e. unit,room,bed)",width:5,isHidden},
    leadDoctor:{default:"",label:"Lead Doctor assigned to Patient",width:4,isHidden},
    
    requestType:{default:"",label:"Request Type",width:12,select:true,options:requestTypes,isHidden},
    
    notes:{default:"",label:"",width:12,multiline:true,rows:4}
}
/*
const HospitalSupportFields = () => {
    const helperClasses = useHelperStyles()  
    const requestTypes = [
        {name:"In-Hospital Referral",description:""},
        {name:"Hospital Transfer",description:""},
        {name:"In-Hospital Counseling",description:""},
        {name:"Hospital Insurance Issues",description:"Hospital Insurance Issues (i.e. coverage for procedures)"},
        {name:"Food Related Inquiries",description:""},
        {name:"Visitation/Shabbos Accomodations",description:""},
        {name:"Other",description:""}
    ]
    return (
        <>
            <Field
                component={TextField}
                name="caseInfo.name"
                type="text"
                label="Name"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <Field
                component={KeyboardDatePicker}
                name="caseInfo.dateOfBirth"
                type="text"
                label="Date Of Birth"
                inputVariant="outlined"
                clearable
                format="MM/dd/yyyy"
                variant="inline"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <br />
            <Field
                component={TextField}
                name="caseInfo.contactName"
                type="text"
                label="Contact Name"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />            
            <Field
                component={TextField}
                name="caseInfo.contactNumber"
                type="text"
                label="Contact Phone Number"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <Field
                component={TextField}
                name="caseInfo.contactRelation"
                type="text"
                label="Contact Relation"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <br />
            <Field
                component={TextField}
                name="caseInfo.currentHospital"
                type="text"
                label="Current Hospital"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            /> 
            <Field
                component={TextField}
                style={{width:"24em"}} 
                name="caseInfo.locationInHospital"
                type="text"
                label="Location In Hospital (i.e. unit,room,bed)"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <Field
                component={TextField}
                name="caseInfo.leadDoctor"
                type="text"
                label="Lead Doctor assigned to Patient"
                style={{width:"20em"}}
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <br />                       
            <Field
                component={TextField}
                select={true}       
                name={`requestType`}                    
                label="Request Type"                                
                variant="outlined"
                margin="normal"
                size="small" 
                className={helperClasses.marginRight}                      
                fullWidth                
            >
                {!!requestTypes && requestTypes.map((requestType) => (
                    <MenuItem key={requestType.name} value={requestType.name}>
                        {requestType.description ? requestType.description : requestType.name}
                    </MenuItem>
                ))}
            </Field>
        </>
    )
}
*/
export default HospitalSupportFields

