import React from "react";
import ConsecutiveSnackbars from './components/Snackbar'


const SnackbarStateContext = React.createContext();
const SnackbarDispatchContext = React.createContext();

function snackbarReducer(state, action) {
  switch (action.type) {
    case "ADD_SNACKBAR_MESSAGE":
      return { queue : [...state.queue, action.payload]   };
    case "REMOVE_SNACKBAR_MESSAGE":
      return { queue: state.queue.slice(1) };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function SnackbarProvider({ children }) {
  var [state, dispatch] = React.useReducer(snackbarReducer, {
    queue : [] 
  });
  return (
    <SnackbarStateContext.Provider value={state}>
      <SnackbarDispatchContext.Provider value={dispatch}>
        <ConsecutiveSnackbars />
        {children}
      </SnackbarDispatchContext.Provider>
    </SnackbarStateContext.Provider>
  );
}

function useSnackbarState() {
  var context = React.useContext(SnackbarStateContext);
  if (context === undefined) {
    throw new Error("useSnackbarState must be used within a SnackbarProvider");
  }
  return context;
}

function useSnackbarDispatch() {
  var context = React.useContext(SnackbarDispatchContext);
  if (context === undefined) {
    throw new Error("useSnackbarDispatch must be used within a SnackbarProvider");
  }
  return context;
}

export { SnackbarProvider, useSnackbarState, useSnackbarDispatch, useAddSnackbarQueue, useRemoveSnackbarQueue };

// ###########################################################
function useAddSnackbarQueue() {    
    const dispatch = useSnackbarDispatch(); 
    return (message,color,actionComponent) => {
        dispatch({
            type: "ADD_SNACKBAR_MESSAGE",
            payload: {message,color,actionComponent, key: new Date().getTime()}
        });
    }
}

function useRemoveSnackbarQueue() {
  const dispatch = useSnackbarDispatch(); 
  return () => {
      dispatch({
          type: "REMOVE_SNACKBAR_MESSAGE"
      });
  }
}
