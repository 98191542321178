import React, {useState} from 'react'
import { Button } from "../../components/Wrappers";
import { Typography } from "../../components/Wrappers";
import useStyles from "./styles";
//import {useKnowledgeBaseStore} from '../../store'
import { useStoreHelpers } from '../../store'
import {useApiHttpState} from '../../context/ApiContext'

const DeleteConfirmation = ({item,close}) => {
    
   // const {  removeKnowlegeBaseItem  } = useKnowledgeBaseStore()
    const {  removeItem  } = useStoreHelpers("knowledgeBase")
    const {loading,api} = useApiHttpState()
    //const { put, post,  response, loading } = useFetch()
    const classes = useStyles();
    const handleSubmit = async () => {
        const [er,response] = await api.delete(`knowledgeBase/${item.id}`);
        if (!er) {
            removeItem(item.id)  
            close();
        } 
        
    }
    return (
        <div className={classes.textCenter} >
            <Typography size="xl" color="text" >Are you sure want to delete:</Typography>
            <Typography size="md" fontStyle="italic" color="text" colorBrightness="secondary">{item.question}</Typography>
            <Button variant="contained" size="small" color="error" pending={loading} className={classes.margin} onClick={handleSubmit} >Delete</Button>
        </div>
    )
}

export default DeleteConfirmation