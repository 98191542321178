const EquipmentFields = {
    phoneNumber:{default:"",label:"Phone Number",width:6},
    address:{default:"",label:"Address",width:6},

    equipmentRequested:{default:"",label:"Equipment Requested",width:4},
    equipmentId:{default:"",label:"Equipment ID #",width:4},
    specialRequests:{default:"",label:"Special Requests",width:4},

    timeNeeded:{default:null,label:"Approximate Time Needed",width:6,componentType:"DateTimePicker"},
    dateReturned:{default:null,label:"Date Returned",width:6,componentType:"DatePicker"}, 
}
/*
const EquipmentFields = () => {
    const helperClasses = useHelperStyles()  
    
    return (
        <>
            <Field
                component={TextField}
                name="caseInfo.phoneNumber"
                type="text"
                label="Phone Number"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <Field
                component={TextField}
                name="caseInfo.address"
                type="text"
                label="Address"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <br />
            <Field
                component={TextField}
                name="caseInfo.equipmentRequested"
                type="text"
                label="Equipment Requested"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <Field
                component={TextField}
                name="caseInfo.equipmentId"
                type="text"
                label="Equipment ID #"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <Field
                component={TextField}
                name="caseInfo.specialRequests"
                type="text"
                label="Special Requests"
                variant="outlined"
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
            <br />            
            <Field
                component={DateTimePicker}
                name="caseInfo.timeNeeded"
                type="text"
                label="Approximate Time Needed"
                inputVariant="outlined"
                clearable
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />            
            <Field
                component={DatePicker}
                name="caseInfo.dateReturned"
                type="text"
                label="Date Returned"
                inputVariant="outlined"
                clearable
                margin="normal"
                size="small"
                className={helperClasses.marginRight}
            />
        </>
    )
}
*/
export default EquipmentFields

