import React from 'react'
import { Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import {  KeyboardDatePicker } from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip';
import { makeStyles } from "@material-ui/core/styles";

import LinearProgress from '@material-ui/core/LinearProgress';
import { Button, Form } from "../../../components/Wrappers";
import { useAddSnackbarQueue} from "../../../context/SnackbarContext";
import { useCloseDialog } from "../../../context/DialogContext";
import { useStoreHelpers } from '../../../store'
import {useApiHttpState} from '../../../context/ApiContext'
import { useHelperStyles } from '../../../styles'
import { useTheme } from '@material-ui/core';
import dateFormat from 'date-fns/format'
import {transformNumberTo10DigitFormat} from '../../../utils/common'

const useStyles = makeStyles((theme) => ({    
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    }    
  }));



const UpsertForm = ({volunteer}) => {    
    const close = useCloseDialog()    
    const {api} = useApiHttpState()
    const helperClasses = useHelperStyles()
    const chipClasses = useStyles()
    const theme = useTheme()
    const addSnackbarQueue = useAddSnackbarQueue()    
    
    const  { addItem, getItemsMap } = useStoreHelpers()  
    const rolesMap = getItemsMap("roles")
    return (
        <Formik
            initialValues={{
                firstName: volunteer ? volunteer.firstName : '',
                lastName:  volunteer ? volunteer.lastName : '', 
                cellphone: volunteer ? volunteer.cellphone : '',
                email:  volunteer ? volunteer.email : '',
                active: volunteer ? volunteer.active : true,
                gender:  volunteer ? volunteer.gender : null,
                notes:  volunteer ? volunteer.notes : null,
                dob:  volunteer ? volunteer.dob : null,
                address:  volunteer ? volunteer.address : null,
                city:  volunteer ? volunteer.city : null,
                state:  volunteer ? volunteer.state : null,
                zip:  volunteer ? volunteer.zip : null,
            }}
            validate={values => {
                const errors = {};
                if (!values.firstName) {
                    errors.firstName = 'Required';
                } 
                if (!values.lastName) {
                    errors.lastName = 'Required';
                }
                if (!values.cellphone) {
                    errors.cellphone = 'Required';
                }
                return errors;
            }}
            onSubmit={ async (values, { setSubmitting }) => {
                let newValues = {...values}
                newValues.cellphone = transformNumberTo10DigitFormat(values.cellphone)
                newValues.dob = dateFormat(newValues.dob,"yyyy-MM-dd")
                const [er,response] = volunteer ? await api.put('/volunteers/'+volunteer.id, {...newValues})  : await api.post('/volunteers', {...newValues} ) 
                setSubmitting(false);                
                if (!er) {
                    addItem(response.data,"volunteers")
                    addSnackbarQueue("Volunteer was " + (volunteer ? "updated" : "created"))  
                    close()                    
                }else {
                    addSnackbarQueue("An error occurred " + (er.message),"error")  
                }
                return;
            }}    
        >
        {({ submitForm, isSubmitting, values }) => (
            <Form>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Field
                    component={TextField}
                    name="firstName"
                    type="text"
                    label="First Name"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginRight}
                />
                
                <Field
                    component={TextField}
                    name="lastName"
                    type="text"
                    label="Last Name"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginLeft}
                />
                <Field
                    component={TextField}
                    name="cellphone"
                    type="text"
                    label="Cellphone"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginLeft}
                />
                <br /> 
                <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email"                    
                    variant="outlined"
                    margin="normal"
                    size="small"  
                    className={helperClasses.marginRight}                  
                />                                  
                <Field
                    component={KeyboardDatePicker}
                    name="dob"
                    type="text"
                    label="D.O.B."   
                    inputVariant="outlined"
                    clearable
                    margin="normal"
                    size="small"
                    className={helperClasses.marginLeft}
                />   
                <Field
                    component={TextField}
                    select
                    name="gender"
                    type="text"
                    label="Gender"                    
                    variant="outlined"
                    margin="normal"
                    size="small"
                    className={helperClasses.marginLeft}
                > 
                    <MenuItem key={"0"}>
                        
                    </MenuItem>
                    <MenuItem key={"Male"} value={"Male"}>
                        Male
                    </MenuItem>
                    <MenuItem key={"Female"} value={"Female"}>
                        Female
                    </MenuItem>
                </Field>
                <br />
                <Field
                    component={TextField}
                    name="address"
                    type="text"
                    label="Address"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                />
                <br />
                <Field
                    component={TextField}
                    name="city"
                    type="text"
                    label="City"                    
                    variant="outlined"
                    margin="normal"
                    size="small"  
                    className={helperClasses.marginRight}                  
                />  
                <Field
                    component={TextField}
                    name="state"
                    type="text"
                    label="State"                    
                    variant="outlined"
                    margin="normal"
                    size="small"  
                    className={helperClasses.marginRight}                  
                />  
                <Field
                    component={TextField}
                    name="zip"
                    type="text"
                    label="Zip"                    
                    variant="outlined"
                    margin="normal"
                    size="small"  
                    className={helperClasses.marginRight}                  
                />  
                <br />
                <Field
                    component={TextField}
                    name="notes"
                    type="text"
                    label="Notes"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    size="small"
                    multiline
                    rows={4}
                /> 
                {isSubmitting && <LinearProgress />}
                <br />
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                >
                    Submit
                </Button>
                </MuiPickersUtilsProvider>
            </Form>
        )}
    </Formik>
    )
}

export default UpsertForm