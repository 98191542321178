import {useSetState} from 'react-use';
import { createContainer } from 'react-tracked';

const initialState = {}; //adjust as needed - add keys for documentation purposes

const useValue = () => useSetState(initialState); //meanwhile using simple set state. maybe should use reducers. But this keeps things simple

//can add in this folder custom hooks for setting parts of the state if desired.


//look inot using immer

const {
  Provider: StoreProvider ,
  useTrackedState: useStore,
  useUpdate: useSetStore,
} = createContainer(useValue);

const useAddItems = () => {
    const setStore = useSetStore()
    return (items, key) => {        
        setStore( (prev) => {
            let newItems = prev[key] ? {...prev[key]} : {} ;
            items.forEach(item => {
                newItems[item.id] = item
            })             
            return {  [key] : newItems }
        })         
    }
}

const useAddItem = () => {
    const store = useStore()
    const setStore = useSetStore()
    return (item, key) => {               
        setStore( (prev) => {
            let newItems = prev[key] ? {...prev[key]} : {} ;            
            newItems[item.id] = item 
            return {  [key] : newItems }
        })         
    }
}

const useRemoveItem = () => {
    const setStore = useSetStore()
    return (id, key) => {
        setStore( (prev) => {
            const newItems = {...prev[key]};
            delete newItems[id];
            return { [key] : newItems }
        })         
    }
}

const useGetItemsArray = () => {
    const store = useStore()    
    return (key, filterFunc) =>  {
        const items = store[key] ? Object.keys(store[key]).map(id => store[key][id]) : null
        return !items || !filterFunc ? items : items.filter(filterFunc)
    }        
}

const useStoreHelpers = (defaultKey) => {
    const store = useStore()
    const setStore = useSetStore()

    const getItem = ( id, key = defaultKey) => {
        return store[key] ? store[key][id] : null
    }
    const getItemsMap = (key = defaultKey) => {
        return store[key]
    }

    const getItemsArray = (key = defaultKey, filterFunc) => {   
        const items = store[key] ? Object.keys(store[key]).map(id => store[key][id]) : null        
        return !items || !filterFunc ? items : items.filter(filterFunc)                
    }

    
    const addItems = (items, key = defaultKey) => {            
        setStore( (prev) => {
            let newItems = prev[key] ? {...prev[key]} : {} ;
            items.forEach( item => newItems[item.id] = item )             
            return { [key] : newItems }
        })         
        
    }

    const addItem = (item, key = defaultKey) => {                        
        setStore( (prev) => {
            let newItems = prev[key] ? {...prev[key]} : {} ;            
            newItems[item.id] = item            
            return {  [key] : newItems }
        })  
    }

    const removeItem = (id, key = defaultKey) => {        
        setStore( (prev) => {
            const newItems = {...prev[key]};
            delete newItems[id];
            return { [key] : newItems }
        })                 
    }


    return { getItemsArray, addItems, addItem, removeItem, getItemsMap, getItem}
}



export {StoreProvider,useStore,useSetStore, useStoreHelpers, useAddItems, useAddItem, useGetItemsArray, useRemoveItem }