import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField';
import { Button } from "../../../components/Wrappers/Wrappers";
import useStyles from "./styles";
import { useAddSnackbarQueue} from "../../SnackbarContext";
import { useCloseDialog} from "../../DialogContext";
import {useApiHttpState} from '../../ApiContext'

const  transformNumberTo10DigitFormat = (number) => {
    number = (number === null || typeof(number) !== 'string') ? '' : number.trim();
    number = number.replace(/\D/g, '');//this will strip non numbers but leave the +
    var hasLeading1 =  number.charAt(0) == "1";
    var startAt =  hasLeading1 ? 1 : 0;
    var numberSliced = number.slice(startAt)
    return "1"+numberSliced
}

const Form = (props) => {
    const close = useCloseDialog()
    const [telNumber,setTelNumber] = useState(props.telNumber ? transformNumberTo10DigitFormat(props.telNumber) : "")
    const [message,setMessage] = useState(props.message ? props.message : "")
    const [formError,setFormError] = useState({});

    const classes = useStyles();    
    const {loading,api} = useApiHttpState()
    const addSnackbarQueue = useAddSnackbarQueue()
    
    const handleTelNumberChange = (e) => {        
        setFormError({})
        setTelNumber(transformNumberTo10DigitFormat(e.target.value))
    }
    
    const handleSubmit = async () => {
        const data = {telNumber,message};
        if(!telNumber || telNumber.length != 11) return setFormError({telNumber:"A valid telephone number is required!"});
        if(!message) return setFormError({message:"A message is required!"});
             
        const [er,response] = await api.post('/sms/send', data) 
        if (!er) {
            addSnackbarQueue("Sent SMS message to "+ telNumber)   
            close()
        } else {
            addSnackbarQueue("Error sending SMS message","danger")
            close()
        }
        
    }
    return (
        <>
           <TextField label="Telephone Number" variant="outlined" fullWidth className={classes.marginBottom} value={telNumber} onChange={ handleTelNumberChange} helperText={formError["telNumber"]} error={!!formError["telNumber"]} /> 
           <TextField label="Message" variant="outlined" fullWidth className={classes.marginBottom} multiline rows={4} value={message} onChange={ e => setMessage(e.target.value)} helperText={formError["message"]} error={!!formError["message"]} />
           <Button variant="contained" size="small" color="success" pending={loading} className={classes.floatRight} onClick={handleSubmit} >Send Text</Button>
        </>
    )
}

export default Form