import { useState } from 'react';
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button , Typography} from "../../components/Wrappers";
import { useHelperStyles } from '../../styles'
import { useStoreHelpers } from '../../store'
import { useCloseDialog } from "../../context/DialogContext";
import { useAddSnackbarQueue} from "../../context/SnackbarContext";


const EmailCaseForm = ({emailCase,assignedToEmail = ""}) => {
    const [email,setEmail] = useState(assignedToEmail)
    const [note,setNote] = useState("")
    const [isSubmitting,setSubmitting] = useState(false)
    const { getItemsArray : getItems } = useStoreHelpers()
    const helperClasses = useHelperStyles() 
    const closeDialog = useCloseDialog()
    const addSnackbarQueue = useAddSnackbarQueue()
    const users = getItems("users")
    const submitForm= async () => {
        if(!email) return alert("You need to enter an email address")
        setSubmitting(true); 
        const [er,response] = await emailCase(email,note)
        setSubmitting(false); 
        closeDialog()
        const message = er ? "An error occurred" : "Household case was emailed to " + email
        const color = !er ? "success" : "error"
        addSnackbarQueue(message,color)     
    }
   
    return (
        <div>
            <Grid container spacing={0}>                        
                <Grid item xs={12} className={helperClasses.itemPadding} >                            
                    <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={users.map((user) => `${user.firstName} ${user.lastName} - ${user.email}`)}                        
                        getOptionLabel={(user) => {
                            // Value selected with enter, right from the input
                            if (typeof user === 'string') {
                                return user;
                            }                            
                            // Regular option
                            return user.email;
                        }}
                        renderOption={(user) => `${user.firstName} ${user.lastName} - ${user.email}`}
                        renderInput={(params) => (
                            <TextField {...params} label="Email" margin="normal" variant="outlined" />
                        )}
                        value={email}
                        onChange={(event, newValue) => {                            
                            setEmail(typeof newValue === 'string' ? newValue : newValue ? newValue.email : newValue);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setEmail(newInputValue);
                        }}
                    />
                </Grid>
                <Grid item xs={12} className={helperClasses.itemPadding} >
                    <TextField onChange={(e) => setNote(e.target.value)} multiline rows={3} fullWidth label="Additional Note" margin="normal" variant="outlined" />             
                </Grid>
                </Grid>
                {isSubmitting && <LinearProgress />}
                        <br />
                        <Typography align="right">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting}
                                onClick={submitForm}
                                className={helperClasses.marginTopBottom}
                            >
                                Submit
                            </Button>

                </Typography> 
                           
        </div>
    )
}

export default EmailCaseForm