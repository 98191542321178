import React from 'react'
import CaseInfoField from './CaseInfoField'
import {caseTypeFields as fieldsMetaData,caseTypeIdMapping} from './caseInfoFieldsMetaData'

const CaseInfoFields = ({caseTypeId,values}) => {
    const fields = caseTypeIdMapping[caseTypeId] && fieldsMetaData[caseTypeIdMapping[caseTypeId]] ? fieldsMetaData[caseTypeIdMapping[caseTypeId]] : {}
    
    return (
        <>
            {Object.keys(fields).filter(name => !fields[name].hasOwnProperty('isHidden')  || !fields[name].isHidden(name,values) ).map(name => <CaseInfoField name={name} {...fields[name]} />)}
        </>
    )
}

export default CaseInfoFields