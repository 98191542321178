import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  People as PeopleIcon,
  PermIdentity as PermIdentityIcon,
  ArrowBack as ArrowBackIcon,
  WbIncandescent as LightBulbIcon,
  Phone as PhoneIcon,
  HomeWork as HomeWorkIcon,
  WorkOutline as WorkOutlineIcon
} from "@material-ui/icons";

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";

const structure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  { id: 1, label: "Knowledge Base", link: "/app/knowledgeBase", icon: <LightBulbIcon /> },
  { id: 2, label: "Call Logs", link: "/app/callLogs", icon: <PhoneIcon /> },
  { id: 3, label: "Households", link: "/app/households", icon: <HomeWorkIcon /> },
  { id: 3, label: "Cases", link: "/app/householdCases", icon: <WorkOutlineIcon /> },
  { id: 11, type: "divider" },
  { id: 12, label: "Users", link: "/app/users", icon: <PeopleIcon /> , roles : ["Super Administrator", "Administrator"]},
  { id: 13, label: "Roles", link: "/app/roles", icon: <PermIdentityIcon /> , roles : ["Super Administrator"]}, 
  { id: 12, label: "Volunteers", link: "/app/volunteers", icon: <PeopleIcon /> , roles : ["Super Administrator", "Administrator","Manage Volunteers"]},
  
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);
  const { user } = useUserState()  
  const filteredMenu = structure.filter( item => !item.roles || user.roles.some(userRole => item.roles.indexOf(userRole.name) > -1 ))
  
  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {filteredMenu.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
