import React, { useEffect , useMemo } from "react";
import {useSocketIo} from '../../context/SocketContext'

import { useCallogs } from "../../apis";

import subMinutes from 'date-fns/subMinutes'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'

import PageTitle from "../../components/PageTitle/PageTitle";

import {
    Grid
} from "@material-ui/core";
import CallLogTable from './Table'
import HouseholdCases from '../householdCases/HouseholdCases'


const CallLogs = ({history,isDashboard}) => {
   
    const { callLogs, isLoading, isError,mutate } = useCallogs(isDashboard)  
    const socketIo = useSocketIo();
    
    useEffect(() => {
        if(socketIo) socketIo.on('message',(data) => {    
            mutate([...callLogs,data])        
        })
    },[socketIo])
    
    const completedCallLogs = useMemo(() => {       
       const dateCutOffCallMustHaveEnded = subMinutes(new Date(), 30)
       const sortedCompetedCalls = callLogs ? callLogs
       .filter(callLog => (!!callLog.finishedAt || isBefore(new Date(callLog.startedAt), dateCutOffCallMustHaveEnded)) && !callLog.hideCall)
       .sort((a,b) => b.id - a.id)   : [] 
       return sortedCompetedCalls.slice(0,25)
    }, [callLogs] )

    const activeCallLogs = useMemo(() => {
       const dateCutOffCallMustHaveEnded = subMinutes(new Date(), 30)
       return  callLogs ? callLogs
       .filter(callLog => (!callLog.finishedAt && isAfter(new Date(callLog.startedAt), dateCutOffCallMustHaveEnded)) && !callLog.hideCall) : []
       .sort((a,b) => b.id - a.id) 
    }, [callLogs] )

    return (
        <>          
          <Grid container spacing={2}> 
            {!!isDashboard && 
                <>
                    <Grid item xs={12}>
                        <CallLogTable loading={isLoading} title="Active Calls" callLogs={activeCallLogs} history={history} /*loadCallerCallLogs={loadCallerCallLogs}*/ />
                    </Grid>
                    <Grid item xs={12}>
                        <CallLogTable loading={isLoading}  title="Recent Calls" callLogs={completedCallLogs} history={history}  /*loadCallerCallLogs={loadCallerCallLogs}*/ />
                    </Grid>
                    <Grid item xs={12}>
                        <HouseholdCases history={history} userOpenCasesView={true} />
                    </Grid>
                </> 
            }
            {!isDashboard && 
                <>
                    <Grid item xs={12}>
                        <CallLogTable title="Call Logs History" loading={isLoading} callLogs={callLogs} history={history}  />
                    </Grid>
                </>
            }
          </Grid>
        </>
      );
}

export default CallLogs
