import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
  container: {    
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",  
  },  
  paperRoot: {
    boxShadow: theme.customShadows.widgetDark,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),    
  },
  textRow: {
    marginBottom: theme.spacing(10),
    textAlign: "center",
  },
  errorCode: {
    fontSize: 148,
    fontWeight: 600,
  },
  safetyText: {
    fontWeight: 300,
    color: theme.palette.text.hint,
  },
  backButton: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    fontSize: 22,
  },
}));
