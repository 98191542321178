import dateFormat from 'date-fns/format'

import MedicalReferralFields from './MedicalReferralFields'
import RideFields from "./RideFields";
import EquipmentFields from './EquipmentFields'
import HospitalSupportFields from './HospitalSupportFields'
import FoodVoucherFields from './FoodVoucherFields'
import financialCounselingFields from './financialCounselingFields'
import campAssistanceFields from './campAssistanceFields'
import familyInCrisisFields from './familyInCrisisFields'
import meisMitzvahFields from './meisMitzvahFields'
import mealsNMoreFields from './mealsNMoreFields'
import stayAtHomeFields from './stayAtHomeFields'
import otherFields from './otherFields'
import chesedShelEmesFields from './chesedShelEmesFields'
import volunteerServicesFields from './volunteerServicesFields'
import resourcesFields from './resourcesFields'
import MentalHealthFields from './MentalHealthFields'



import { camelCaseToWords, formatPhoneNumber } from '../../utils'

const caseTypeIdMapping = {    
    1 : "MedicalReferralFields",
    2 : "EquipmentFields",
    3 : "RideFields",
    4 : "HospitalSupportFields",
    5 : "FoodVoucherFields",   
    6 : "financialCounselingFields", 
    7 : "campAssistanceFields",
    8 : "familyInCrisisFields",
    9 : "mealsNMoreFields",
    10: "meisMitzvahFields",
    11: "stayAtHomeFields",
    12: "otherFields",
    13: "chesedShelEmesFields",
    14: "volunteerServicesFields",
    15: "resourcesFields",
    16: "MentalHealthFields"
};


const caseTypeFields = {
    MedicalReferralFields,
    EquipmentFields,
    RideFields,  
    HospitalSupportFields,
    FoodVoucherFields,
    financialCounselingFields,
    campAssistanceFields,
    familyInCrisisFields,
    meisMitzvahFields,
    mealsNMoreFields,
    stayAtHomeFields,
    otherFields,
    chesedShelEmesFields,
    volunteerServicesFields,
    resourcesFields,
    MentalHealthFields
}; 

const getDefaultValues = (caseTypeId) => {
    return caseTypeIdMapping.hasOwnProperty(caseTypeId) ? Object.keys(caseTypeFields[caseTypeIdMapping[caseTypeId]]).reduce((obj,fieldName) => ({...obj, [fieldName]: caseTypeFields[caseTypeIdMapping[caseTypeId]][fieldName].default}),{}) : {}
};

const getCreatedDate = (householdCase) => {
    return householdCase ? dateFormat(new Date(householdCase.createdAt),"MMM do, h:mm:ssa") : ""
}
const getCaseTypeName = (caseTypes,householdCase) => {
    return !!caseTypes && !!householdCase ? caseTypes[householdCase.caseTypeId].name : ""
}
const getAssignedTo = (users,householdCase) => {
    return !!users && !!householdCase ? users[householdCase.assignedTo] : {}
}
const getCreatedby = (users,householdCase) => {
    return !!users && !!householdCase ? users[householdCase.createdBy] : {}
}
const getVolunteer = (volunteers,householdCase) => {
    return !!volunteers && !!householdCase && !!householdCase.volunteerId ? volunteers[householdCase.volunteerId] : {}
}    
const getVolunteers = (householdCase) => {
    return !!householdCase && !!householdCase.volunteers ? householdCase.volunteers : []
}
const getStatus = (caseStatuses,householdCase) => {
    return !!caseStatuses && !!householdCase ? caseStatuses[householdCase.statusId] : ""
}

const getHouseholdCaseLabelAndValuesForEmail = (household,householdCase,caseTypes,users,volunteers,caseStatuses,phoneNumberTypes) => {
        let  emailHtml = "";   
        emailHtml += `<h4>Household Information</h4>`
        //change this to put all into 3 lines like it is on top of household page - maybe add in open case total?
        emailHtml += `<div><em>${household.familyName}, ${household.husbandName} & ${household.wifeName}</em></div>`;
        emailHtml += `<div>${household.address}  ${household.city},${household.state} ${household.postalCode}</div>`;
        const phoneNumbers  = household.phoneNumbers && household.phoneNumbers.map(phoneNumber => {
            return `<a href=tel:${formatPhoneNumber(phoneNumber.phoneNumber)}>${formatPhoneNumber(phoneNumber.phoneNumber)} - ${!!phoneNumberTypes ? phoneNumberTypes[phoneNumber.phoneTypeId].name : ""}</a>`
        }).join(", ")
        emailHtml += `<div><em>${phoneNumbers}</em></div>`;

        const emails = household.emails && household.emails.map(email => {
            return `<a href=mailto:${email.email}>${email.email}</a>`
        }).join(" ")
        emailHtml += `<div><em>${emails}</em></div>`;
        //general case info
        emailHtml += `<h4>Case Details</h4>`
        Object.keys(householdCase).forEach(key => {
            let value = household[key];
            let label = camelCaseToWords(key)
            if(key == "createdAt") value = getCreatedDate(householdCase)
            if(key == "createdBy") {
                const createdBy = getCreatedby(users,householdCase)
                value = `${createdBy.firstName} ${createdBy.lastName} -  ${createdBy.email}` 
            }            
            if(key == "caseTypeId") {
                value = getCaseTypeName(caseTypes,householdCase)
                label = "Case Type"
            }
            if(key == "assignedTo") {
                const user = getAssignedTo(users,householdCase)
                value = `${user.firstName} ${user.lastName}`
                label = "Assigned To"
            }            
            if(key == "volunteerId" ) {
                const volunteer = getVolunteer(volunteers,householdCase)
                value = volunteer ? `${volunteer.firstName ? volunteer.firstName : ""} ${volunteer.lastName ? volunteer.lastName : ""}` : ""
                label = "Volunteer"
            }
            if(key == "volunteers") {
                const volunteers = getVolunteers(householdCase)
                value = volunteers ? volunteers.map(volunteer => `${volunteer.firstName ? volunteer.firstName : ""} ${volunteer.lastName ? volunteer.lastName : ""}`).join(", ")  : ""
                label = "Volunteers"
            }          
            if(key == "statusId") {
                const status = getStatus(caseStatuses,householdCase)
                value = status.statusName
                label = "Status"
            }                  
            if(value && key != "updatedAt" && key != "caseInfo" )emailHtml += `<div><b>${label}:</b> <em>${value}</em></div>`;
        })
        const caseInfo = householdCase ? JSON.parse(householdCase.caseInfo) : {}
        emailHtml +=  getCaseInfoLabelAndValues(caseInfo,householdCase.caseTypeId,'html') 
        return emailHtml
}

const getCaseInfoLabelAndValues = (caseInfoValues,caseTypeId,displayType = 'html') => {
    let result = '';
    let caseFields = !!caseTypeIdMapping.hasOwnProperty(caseTypeId) ? caseTypeFields[caseTypeIdMapping[caseTypeId]] : {};

    Object.keys(caseInfoValues).forEach(name => {
        const fieldMetaData = caseFields[name];
        if(!fieldMetaData.hasOwnProperty('isHidden')  || !fieldMetaData.isHidden(name,{caseInfo:caseInfoValues})) {
            let value = caseInfoValues[name];//if date datetime or time format - if select maybe get label if different
            if(fieldMetaData.componentType && value) {//TimePicker, DatePicker, KeyboardDatePicker, DateTimePicker 
                //dateFormat(new Date(row.startedAt),"MMM do, h:mm:ssa")
                const dateTime = new Date(value)
                if(fieldMetaData.componentType == "TimePicker") value = dateFormat(dateTime,"h:mma")
                if(fieldMetaData.componentType == "DatePicker" || fieldMetaData.componentType == "KeyboardDatePicker" ) value = dateFormat(dateTime,"MMM do")
                if(fieldMetaData.componentType == "DateTimePicker") value = dateFormat(dateTime,"MMM do, h:mm:ssa")
            }
            const label = fieldMetaData.label ? fieldMetaData.label : camelCaseToWords(name);
            result += (displayType == 'html') ? `<div><b>${label}:</b> <em>${value ? value : ""}</em></div>` : `${label}: ${value ? value : ""}\n`;
        }        
    })
    return result;
};

const yesNoOptions = [
    {value:"",label:"Choose Option"},
    {value:"Yes"},
    {value:"No"},
];

export {
    caseTypeIdMapping,
    getDefaultValues,
    caseTypeFields,
    yesNoOptions,    
    getCaseInfoLabelAndValues,
    getHouseholdCaseLabelAndValuesForEmail,
    getCreatedDate,
    getCaseTypeName,
    getAssignedTo,
    getVolunteer,
    getStatus
}
