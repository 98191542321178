import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    margin: {
        margin: theme.spacing(.5),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    floatRight: {
        float: "right"
    }
  }));
  