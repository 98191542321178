import React , {useState,useEffect} from 'react'
import socketIOClient from "socket.io-client";

const SocketIoContext = React.createContext();

const SocketProvider = ({ children, endpoint = '/' }) => {    
    const [socketState,setSocket] =  useState()
    useEffect(() => {
        const socket = socketIOClient(endpoint);
        setSocket(socket)
        return () => socket && socket.disconnect();
    },[endpoint])
  
    return (  
       <SocketIoContext.Provider value={socketState}>    
          {children}    
        </SocketIoContext.Provider>   
    );
}
  function useSocketIo() {
    var context = React.useContext(SocketIoContext);
    if (context === undefined) {
      throw new Error("useSocketIo must be used within a SocketProvider");
    }
    return context;
  }

  export  {SocketProvider , useSocketIo}