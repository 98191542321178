const maritalOptions = [
    {value:"",label:"Choose Option"},
    {value:"Married"},
    {value:"Single"},
    {value:"Divorced"},
]
const yesNoOptions = [
    {value:"",label:"Choose Option"},
    {value:"Yes"},
    {value:"No"},
]

const campAssistanceFields = {
    name:{default:"",label:"Name",width:4},
    homePhone:{default:"",label:"Home Phone",width:4},
    cellPhone:{default:"",label:"Cell Phone",width:4},
    
    homeAddress:{default:"",label:"Home Address",width:12},

    maritalStatus:{default:"",label:"Marital Status",width:4,select:true,options:maritalOptions},
    employmentOfCaller:{default:"",label:"Employment Of Caller",width:4},
    employmentOfSpouse:{default:"",label:"Employment Of Spouse",width:4},

    numberOfChildren:{default:"",label:"Number Of Children",width:3},
    numberOfChildrenToCamp:{default:"",label:"Number going to camp",width:3},
        
    shulAffiliation:{default:"",label:"Shul Affiliation",width:3},
    shulRabbi:{default:"",label:"Shul Rabbi",width:3},

    receivedPastAchiezerAid:{default:"",label:"Received Past Achiezer Aid",width:4,select:true,options:yesNoOptions},
    receivingOtherAssistance:{default:"",label:"",width:4,helperText:"(i.e. other organizations or indivduals)"},
}

export default campAssistanceFields

