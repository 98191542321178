import React, { useEffect } from "react";

import dateFormat from 'date-fns/format'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Button ,Typography } from "../../components/Wrappers";
import PageTitle from "../../components/PageTitle";

import HouseholdHeader from '../household/HouseholdHeader'

import { useParams } from "react-router-dom";
import { useStoreHelpers } from '../../store'
import {useApiHttpState} from '../../context/ApiContext'
import { useHelperStyles } from '../../styles'
import UpsetForm from './UpsertForm'
import {getCaseInfoLabelAndValues,
        getHouseholdCaseLabelAndValuesForEmail,
        getCreatedDate,
        getCaseTypeName,
        getAssignedTo,
        getVolunteer
} from './caseInfoFieldsMetaData'
import { useOpenDialog, useOpenSmsDialog } from "../../context/DialogContext";
import Documents from "./Documents"
import EmailCaseForm from './EmailCaseForm'

import { formatPhoneNumber } from '../../utils'

const HouseholdCase = ({history}) => {
    const {caseId,householdId} = useParams()
    const { api } = useApiHttpState()
    const { addItem, addItems , getItem , getItemsMap} = useStoreHelpers()     
    const openDialog = useOpenDialog() 
    const openSmsDialog = useOpenSmsDialog()
    const helperClasses = useHelperStyles() 
    const loadHousehold = async  () => {
        const [er,response] = await api.get(`/households/${householdId}`,{useLoader:true}) 
        if (!er) addItem(response.data,"households")        
    }
    const loadHouseholdCase = async  () => {
        const [er,response] = await api.get(`/householdCases/${caseId}`,{useLoader:true}) 
        if (!er) addItem(response.data,"householdCases")        
    }
    const loadCaseTypes = async  () => {
        const [er,response] = await api.get('/caseTypes/',{useLoader:true})        
        if (!er) addItems(response.data,"caseTypes")        
    }
    const loadCaseStatuses = async  () => {
        const [er,response] = await api.get('/caseStatuses/',{useLoader:true})        
        if (!er) addItems(response.data,"caseStatuses")        
    }    
    const loadUsers = async  () => {
        const [er,response] = await api.get('/users/forAssigning',{useLoader:true})        
        if (!er) addItems(response.data,"users")//need to take into account the users page should not get overwritten        
    }
    const loadVolunteers = async  () => {
        const [er,response] = await api.get('/volunteers',{useLoader:true})        
        if (!er) addItems(response.data,"volunteers")        
    }
    useEffect(() => {        
        loadHousehold()
        loadHouseholdCase() 
        loadCaseTypes()             
        loadCaseStatuses()
        loadUsers()
        loadVolunteers()
    },[])  
    const openHousehold = () => {
        history.push(`/app/household/${householdId}`)
    }
    const household = getItem(householdId,"households")
    const householdCase = getItem(caseId,"householdCases")
    const caseTypes = getItemsMap("caseTypes");
    const caseStatuses = getItemsMap("caseStatuses");
    const users = getItemsMap("users")
    const volunteers = getItemsMap("volunteers")
    const phoneNumberTypes = getItemsMap("phoneNumberTypes");    
       
    const emailCase = async (email,notes) => {
        let emailHtml = `<div>${notes}</div>`
        emailHtml += getHouseholdCaseLabelAndValuesForEmail(household,householdCase,caseTypes,users,volunteers,caseStatuses,phoneNumberTypes)        
        const [er,response] = await api.post('/email/sendCase',{body:{to:email,subject:"Case",body:emailHtml,caseId:householdCase.id},useLoader:true})  
        return [er,response]
    }
    const handleEmailCase = () => {
        const user = getAssignedTo(users,householdCase)
        openDialog("Email Case",<EmailCaseForm emailCase={emailCase} assignedToEmail={user.email ? user.email : "" }  />)
    }
    
    const handleSendSmsMessage = () => {
        //get volunteer if set
        //const volunteer = getVolunteer(volunteers,householdCase)
        const caseVolunteers = householdCase.volunteers
        const phoneNumber = caseVolunteers && caseVolunteers.length ? caseVolunteers[0].cellphone  : ``
        let message = ``
        message += `Household Information\n`
        message += `${household.familyName}, ${household.husbandName} & ${household.wifeName}\n`;        
        const phoneNumbers  = household.phoneNumbers && household.phoneNumbers.map(phoneNumber => {
            return `${formatPhoneNumber(phoneNumber.phoneNumber)} - ${!!phoneNumberTypes ? phoneNumberTypes[phoneNumber.phoneTypeId].name : ""}`
        }).join(", ")
        message += `${phoneNumbers}\n\n`;
        message += `CaseType: ${getCaseTypeName(caseTypes,householdCase)}\n`;
        const caseInfo = householdCase ? JSON.parse(householdCase.caseInfo) : {}
        message +=  getCaseInfoLabelAndValues(caseInfo,householdCase.caseTypeId,'text')
        openSmsDialog(`${phoneNumber}`,message)
    }
    return (
        <>
            <PageTitle title="Household Case" button={<div><Button color="primary" variant="contained" onClick={openHousehold} >Back to Household</Button> <Button color="secondary" variant="contained"  onClick={handleEmailCase}>Email Case</Button> <Button color="info" variant="contained"  onClick={handleSendSmsMessage}>Send Volunteer SMS</Button></div>} />
            {!!household && <HouseholdHeader household={household} />}
            
            {!!householdCase && householdCase.caseInfo &&
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Paper className={ helperClasses.paperPadding + " " + helperClasses.marginTopBottom}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} className={helperClasses.itemPadding} >
                                        <Typography variant="h4" align="center" >Case Type: {getCaseTypeName(caseTypes,householdCase)}</Typography>
                                        <br />
                                        </Grid>
                                    <Grid item xs={6} className={helperClasses.itemPadding} >
                                        <Typography variant="subtitle">Created on: {getCreatedDate(householdCase)} </Typography>
                                    
                                    </Grid>
                                    <Grid item xs={6} className={helperClasses.itemPadding} >
                                        <Typography variant="subtitle" align="right">Last Updated: {dateFormat(new Date(householdCase.updatedAt),"MMM do, h:mm:ssa")}</Typography>
                                    
                                    </Grid>
                                </Grid>
                                <UpsetForm householdCase={householdCase} household={household} partialMode="main" />
                            </Paper>
                            </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper className={ helperClasses.paperPadding + " " + helperClasses.marginTopBottom} >                                
                                    <Typography variant="h5">Details</Typography>
                                    <br />
                                    <UpsetForm householdCase={householdCase} household={household} partialMode="details" />
                                
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Documents  documents={householdCase.documents} users={users} caseId={caseId} householdId={householdId} reloadHouseholdCase={loadHouseholdCase} /> 
                        </Grid>
                    </Grid>
                    
                </>
            }
        </>
    )

}

export default HouseholdCase