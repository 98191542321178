import React from "react";
import Dialog from './components/Dialog'
import DialogConfirmContent from './components/DialogConfirmContent'
import SendSmsForm from './components/SendSms'

var DialogStateContext = React.createContext();
var DialogDispatchContext = React.createContext();

function dialogReducer(state, action) {
  switch (action.type) {
    case "OPEN_DIALOG":
      return { ...state, isOpen: true, ...action.payload  };
    case "CLOSE_DIALOG":
      return { ...state, isOpen: false, title : "" , content: null, requiresAuthentication: true  };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function DialogProvider({ children }) {
  var [state, dispatch] = React.useReducer(dialogReducer, {
    isOpen: false, title : "", content: null, requiresAuthentication: true
  });
  return (
    <DialogStateContext.Provider value={state}>
      <DialogDispatchContext.Provider value={dispatch}>
        <Dialog />  
        {children}
      </DialogDispatchContext.Provider>
    </DialogStateContext.Provider>
  );
}

function useDialogState() {
  var context = React.useContext(DialogStateContext);
  if (context === undefined) {
    throw new Error("useDialogState must be used within a DialogProvider");
  }
  return context;
}

function useDialogDispatch() {
  var context = React.useContext(DialogDispatchContext);
  if (context === undefined) {
    throw new Error("useDialogDispatch must be used within a DialogProvider");
  }
  return context;
}

export { DialogProvider, useDialogState, useDialogDispatch, useCloseDialog, useOpenDialog , useOpenConfirmationDialog, useOpenSmsDialog };

// ###########################################################
function useCloseDialog() { 
    const dispatch = useDialogDispatch(); 
    return () => {
        dispatch({
            type: "CLOSE_DIALOG",
        });
    }
}

function useOpenDialog() {
    const dispatch = useDialogDispatch(); 
    return (title,content,props) => {        
        dispatch({
            type: "OPEN_DIALOG",
            payload: {title,content, ...props}
        });
    }
}

const useOpenConfirmationDialog = () => {
    const openDialog = useOpenDialog()
    return (confirmationText,onSubmit,title,props) => {
        openDialog(title ? title : "Confirm",<DialogConfirmContent confirmationText={confirmationText} onSubmit={onSubmit} />,props)
    }
}

const useOpenSmsDialog = () => {
  const openDialog = useOpenDialog();
  return (telNumber,message) => {    
    openDialog("Send SMS MEssage", <SendSmsForm telNumber={telNumber} message={message}  />)
  }
}

